import React, { useEffect, useState } from 'react'
import $$ from 'cmn-utils'
import { ContentModal, PuffLoading } from '@/components/index'

import './view.less'

// const types = ['', '政策', '新闻', '动态']

const ViewNews = ({ visible, id, onCancel }) => {
  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    if (visible) {
      setLoading(true)
      queryDetail()
    }
  }, [visible])

  const queryDetail = () => {
    $$.post('/news/detail', { id }).then(res => {
      setLoading(false)
      if (res.code !== 1) return
      setDetail(res.data)
    })
  }

  return (
    visible ? <ContentModal
      className='news-detail'
      title='内容详细'
      visible={visible}
      onCancel={onCancel}
    >
      {
        (loading || detail === null) ? <div className="content-layout-loading">
          <PuffLoading></PuffLoading>
        </div> :
          <>
            <div className='title-wrapper'>{detail?.title}</div>
            <div className='subtitle-wrapper'>
              <span className='subtitle-item'>发布时间：{detail?.crtAt}</span>
              {/* <span className='subtitle-item'>发布来源：{
                detail?.provinceVo?.areaName + detail?.cityVo?.areaName + detail?.districtVo?.areaName
              }</span> */}
              <span className='subtitle-item'>发布人：{detail?.crtByName}</span>
            </div>
            <p className="divider"></p>
            <div className="news-content-wrapper" dangerouslySetInnerHTML={{ __html: detail?.content }}></div>
          </>
      }
    </ContentModal>
      : <></>
  )
}

export default ViewNews