import React, { useEffect } from 'react'
import $$ from 'cmn-utils'
import { ContentModal, TriangleBorderWrapper, SingleLineChart } from 'components'
// import './line_chart_modal.less'

const options = [
	{ label: '蜜源种类', key: 0, unit: '种' },
	{ label: '蜜源区域数', key: 1, unit: '个' },
	{ label: '总蜜源面积', key: 2, unit: '亩' },
	{ label: '蜂蜜预产量', key: 3, unit: '吨' },
	{ label: '可放置蜂箱', key: 4, unit: '箱' },
	{ label: '已放置蜂箱', key: 5, unit: '箱' },
]
const LineChartModal = props => {
	const [dataSource, setDataSource] = React.useState(null)
	const [unit, setUnit] = React.useState(options[props.nowType].unit)

	useEffect(() => {
		setUnit(options[props.nowType].unit)
	}, [props.nowType])

	useEffect(() => {
		$$.post('/data/nectar/thread', {}).then(res => {
			if (res.code !== 1) return

			setDataSource({
				x: res.data.map(d => d.crtTime).slice(-7),
				y: [
					res.data.map(d => d.typesOfNectar).slice(-7),
					res.data.map(d => d.nectarAreas).slice(-7),
					res.data.map(d => d.nectarSourceArea).slice(-7),
					res.data.map(d => d.yield).slice(-7),
					res.data.map(d => d.beehiveCanBePlaced).slice(-7),
					res.data.map(d => d.beehivePlaced).slice(-7),
				]
			})
		})
	}, [])

	const _dataSource = dataSource

	return (
		<ContentModal
			className="line-chart-modal"
			visible={props.visible}
			title="蜜源信息统计"
			onCancel={props.onCancel}
		>
			<div className="line-chart-modal-content">
				<div className="f-l-options">
					{options.map(o => (
						<TriangleBorderWrapper
							key={o.key}
							ghost={props.nowType !== o.key ? 'true' : undefined}
							onClick={_ => {
								props.typeChange(o.key)
								setUnit(options[o.key].unit)
							}}
						>
							{o.label}
						</TriangleBorderWrapper>
					))}
				</div>
				<div className="charts">
					<SingleLineChart
						id="beefarm-info-day"
						data={{ x: _dataSource?.x, y: _dataSource?.y[props.nowType] }}
						unit={unit}
					></SingleLineChart>
				</div>
			</div>
		</ContentModal>
	)
}

export default LineChartModal
