import React, { useState } from 'react'
import $$ from 'cmn-utils'
import { ContentLayout, MultiLineChart, PuffLoading } from 'components'
import LineChartModal from './line_chart_modal'
import ReportInfoGuide from '../report_info_guide'

import './index.less'

const Index = props => {
	const [modalVisible, setModalVisible] = React.useState(false)
	const [nowType, setNowType] = React.useState(0)
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState(null)

	React.useEffect(() => {
		$$.post('/data/technical/supports/month/thread', {}).then(res => {
			setLoading(false)
			if (res.code !== 1) return
			setData(res.data)
		})
	}, [])

	return (
		<ContentLayout title="上报信息" icon="ic_information">
			<div className="report-infomation">
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">总技术指导:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(0)
								setModalVisible(true)
							}}
						/>
					</div>
					<div className="info-item-content">{props.data?.technicalSupports ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">当月技术指导:</span>
					</div>
					<div className="info-item-content">{props.data?.monthTechnicalSupports ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">总养蜂服务:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(1)
								setModalVisible(true)
							}}
						/>
					</div>
					<div className="info-item-content">{props.data?.beekeepingService ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">当月养蜂服务:</span>
					</div>
					<div className="info-item-content">{props.data?.monthBeekeepingService ?? 0}</div>
				</div>
			</div>

			<ReportInfoGuide loading={loading} data={props.data}></ReportInfoGuide>

			<div style={{height: '100px'}}></div>

			{/* {loading ? <div className="content-layout-loading">
				<PuffLoading></PuffLoading>
			</div> : <MultiLineChart
					dataSource={{
						unit: '次',
						x: [],
						y: [
							{
								name: '技术指导'+ loading,
								data: data?.map(d => d.technicalSupports),
							},
							{
								name: '养蜂服务',
								data: data?.map(d => d.beekeepingService),
							},
						],
					}}
				/>}
			{modalVisible && <LineChartModal
				visible={modalVisible}
				typeChange={type => setNowType(type)}
				nowType={nowType}
				onCancel={_ => setModalVisible(false)}
			/>} */}

			
			
		</ContentLayout>
	)
}

export default Index
