import React, { useEffect, useState } from 'react'
import $$ from 'cmn-utils'
import { ContentLayout, IScrollBoard, PuffLoading, ContentModal, PieChart } from 'components'

const NectarType = props => {
	const [loading, setLoading] = useState(true)
	const [modalVisible, setModalVisible] = useState(false)
	const [dataSource, setDataSource] = useState(null)
	const [chartData, setChartData] = useState(null)

	useEffect(() => {
		$$.post('/data/nectar/species', {}).then(res => {
			if (res.code !== 1) {
				setLoading(false)
				return
			}
			const data = res.data.map(d => [
				`<span style="color: white">${d.nectarName}</span>`,
				d.area,
				d.preProduction,
			])
			const chartData = res.data.map(d => ({
				name: d.nectarName,
				data: [d.area, d.preProduction],
				color: d.colorValue,
			}))
			setChartData(chartData)
			setDataSource(data)
			setLoading(false)
		})
	}, [])

	return (
		<ContentLayout title="蜜源种类" icon="ic_fengyuan" onActionClick={_ => setModalVisible(true)}>
			{loading ? (
				<div className="content-layout-loading">
					<PuffLoading></PuffLoading>
				</div>
			) : (
					<IScrollBoard
						name="nectar"
						rowNum={3}
						waitTime={3000}
						showDian={false}
						align={['center', 'center', 'center', 'center']}
						header={['蜜种', '蜜源面积(亩)', '预产量(吨)']}
						// header={['蜜种', '蜜源面积(万亩)', '预产量(吨)']}
						data={dataSource}
					/>
				)}
			<ContentModal
				className="line-chart-modal"
				visible={modalVisible}
				title="蜜源种类统计"
				onCancel={_ => setModalVisible(false)}
			>
				<PieChart
					titles={[
						{ title: '蜜源面积', unit: '亩' },
						// { title: '蜜源面积', unit: '万亩' },
						{ title: '蜂蜜预产量', unit: '吨' },
					]}
					data={chartData}
				/>
			</ContentModal>
		</ContentLayout>
	)
}

export default NectarType
