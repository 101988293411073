import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import $$ from 'cmn-utils'
import { useWillMount, Toast } from 'components'
import adCodes from 'jsons/ad_code'
import ReportInfo from './report_info'
import NewsList from './news_list'
import BeekeepGovernance from './beekeep_governance'
import GoalProgram from './goal_program'
import LeaderStyle from './leader_style'

import './index.less'

let mapStack = []
const AskHelpPage = props => {

  const history = useHistory()
  const [nowArea, setNowArea] = React.useState(mapStack[0])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useWillMount(() => {
    const currentArea = $$.getStore('currentArea')
    $$.setStore('currentAreaName', currentArea.areaName)
    $$.setStore('currentAreaCode', currentArea.areaCode)
    $$.setStore('currentAreaLevel', currentArea.layer)
    mapStack = [currentArea.areaName]
  })

  useEffect(() => {
    $$.post('/data/technical/supports/baseInfo', {}).then(res => {
      if (res.code === -2) {
        Toast.warning(res.msg)
        $$.removeStore('username')
        $$.removeStore('token')
        $$.removeStore('accountArea')
        $$.removeStore('currentAreaName')
        $$.removeStore('currentArea')
        $$.removeStore('master')
        history.push('/login')
        return
      }
      setLoading(false)
      if (res.code !== 1) return
      setData(res.data)
    })
  }, [])

  const onAreaChange = (areaName, isPop) => {
    if (isPop) {
      if (mapStack.length > 1) {
        mapStack.pop()
      }
    } else {
      if (mapStack[mapStack.length - 1] !== areaName) mapStack.push(areaName)
    }
    $$.setStore('currentAreaName', mapStack[mapStack.length - 1])
    document.getElementById('header-now-area').innerText = mapStack[mapStack.length - 1]
    const code = adCodes[mapStack[mapStack.length - 1]] ?? 100000
    $$.setStore('currentAreaCode', code)
    $$.post('/sys/area/info', { areaCode: code }).then(res => {
      if (res.code !== 1) return
      $$.setStore('currentAreaLevel', res.data.hasOwnProperty('layer') ? res.data.layer : 0)
      setNowArea(mapStack[mapStack.length - 1])
    })
  }

  return (<div className="ask-help-page">
    <div className="grid-item item-1">
      <ReportInfo data={data}></ReportInfo>
    </div>
    <div className="grid-item item-2">
      <LeaderStyle></LeaderStyle>
    </div>
    <div className="grid-item item-3">
      <GoalProgram loading={loading} data={data}></GoalProgram>
    </div>
    <div className="grid-item item-4">
      <BeekeepGovernance loading={loading} data={data}></BeekeepGovernance>
    </div>
    <div className="grid-item item-5">
      <NewsList icon='ic_zhishi' eventName='knowledge' row={5} title='养蜂知识' type={6}></NewsList>
    </div>
    <div className="grid-item item-6">
      <NewsList icon='ic_zhidao' eventName='work' row={5} title='指导工作安排' type={4}></NewsList>
    </div>
    <div className="grid-item item-7">
      <NewsList icon='ic_dongtai' eventName='service' row={6} title='服务动态' type={3}></NewsList>
    </div>
  </div>)
}

export default AskHelpPage