/**
 * 蜂场信息面板
 */
import React from 'react'
import Modal from 'components/modal/index'
import $$ from 'cmn-utils'
import PuffLoader from 'react-spinners/PuffLoader'
import icon_location from 'assets/beefarm/icon_diqu2.png'
import Image from 'rc-image'
import 'rc-image/assets/index.css'

import 'components/modal/card_modal.less'

const BeefarmInfoModal = props => {
	const [loading, setLoading] = React.useState(true)
	const [dataSource, setDataSource] = React.useState(null)

	React.useEffect(() => {
		if (props.farmId === null) return
		$$.post('/beeFarm/data/panel', { farmId: props.farmId }).then(res => {
			if (res.code !== 1) return
			// console.log(res.data)
			setDataSource(res.data)
			setLoading(false)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.farmId])

	return (
		<Modal
			className="beefarm-modal"
			visible={props.visible}
			style={{ width: 365, left: '80%' }}
			onCancel={() => {
				setLoading(true)
				props.onCancel()
			}}
			loadingCenter={loading}
		>
			{loading ? (
				<PuffLoader loading={loading} size={60} color="#17b7e3" />
			) : (
					<>
						<div className="title">
							<span>{dataSource?.farm.name}</span>
							<img src={icon_location} alt="" />
						</div>
					
						<div className="content">
							<span className="district">{`${dataSource?.province?.areaName}·${dataSource?.city?.areaName}·${dataSource?.district?.areaName}`}</span>
							<span className="address">{dataSource?.farm.address}</span>
							<span className="content-title">蜂场信息</span>
							<span className="data-row">
								<span className="label">蜂种：<span className="value">{dataSource?.beeSpecies}</span></span>
							</span>
							<span className="data-row">
								<span className="label">当前蜜源：<span className="value">{dataSource?.nectarSource}</span></span>
							</span>
							<span className="data-row">
								<span className="label">蜂箱/蜂群数：<span className="value">{dataSource?.farm?.hivesNumber}</span></span>
							</span>
							<span className="data-row">
								<span className="label">预产量：<span className="value">{dataSource?.estimatedOutput}</span></span>
							</span>
							<span className="content-title">蜂场档案</span>
							<span className="data-row">
								<span className="label">蜂场照片：</span>
								<span className="value">
									{dataSource?.farm?.relatedPictures !== '' && <Image.PreviewGroup
										icons={{ left: <i className='rc-image-nav-left'></i>, right: <i className='rc-image-nav-right'></i> }}>
										{dataSource?.farm?.relatedPictures.split(',').map((p, i) => <Image key={i} width={50} height={50} src={p} />)}
									</Image.PreviewGroup>}
								</span>
							</span>
							<span className="data-row">
								<span className="label">蜂场介绍：<span className="value">{dataSource?.farm.introduction}</span></span>
							</span>
							<span className="content-title" style={{ paddingLeft: 6 }}>
							养蜂户信息
						</span>
							<span className="data-row">
								<span className="label">养蜂户：<span className="value">{dataSource?.beekeeper.realName}</span></span>
							</span>
							<span className="data-row">
								<span className="label">联系电话：<span className="value">{dataSource?.beekeeper.phone}</span></span>
							</span>
						</div>
						{/* <div className="footer">*进入区域图可查看此地区更多数据信息！</div> */}
					</>
				)}
		</Modal>
	)
}

export default BeefarmInfoModal
