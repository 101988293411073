import React, { useEffect } from 'react'

const SingleLineChart = props => {
	const getBaseOption = () => {
		const axisLabelFontSize = 16

		return {
			tooltip: {
				trigger: 'axis',
				backgroundColor: '#54b6dd',
				borderColor: 'transparent',
				padding: [8, 24],
				formatter: function (params) {
					return params[0].data
				},
				textStyle: { color: 'black' },
				axisPointer: {
					lineStyle: {
						color: {
							type: 'linear',
							x: 0,
							y: 0,
							x2: 0,
							y2: 1,
							colorStops: [
								{
									offset: 0,
									color: 'rgba(0, 255, 233,0)',
								},
								{
									offset: 0.5,
									color: 'rgba(255, 255, 255,1)',
								},
								{
									offset: 1,
									color: 'rgba(0, 255, 233,0)',
								},
							],
							global: false,
						},
					},
				},
			},
			grid: {
				top: '15%',
				left: '5%',
				right: '5%',
				bottom: '5%',
				containLabel: true,
			},
			xAxis: [
				{
					type: 'category',
					axisLine: {
						show: true,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						show: false,
						color: '#2590B0',
						fontWeight: '900',
						fontSize: axisLabelFontSize,
						interval: 0,
					},
					splitLine: {
						lineStyle: {
							color: '#2590B0',
						},
						show: false,
					},
					boundaryGap: false,
					data: props.data?.x,
				},
			],
			yAxis: [
				{
					type: 'value',
					min: 0,
					name: `(${props.unit})`,
					nameTextStyle: {
						fontWeight: '900',
						color: 'rgba(37, 144, 176, 1)',
					},
					splitNumber: 4,
					splitLine: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#2590B0',
						},
					},
					minInterval: 1,
					axisLabel: {
						show: true,
						margin: 20,
						fontSize: axisLabelFontSize,
						fontWeight: '900',
						color: '#2590B0',
					},
					axisTick: {
						show: false,
					},
				},
			],
			series: [
				{
					name: '三',
					type: 'line',
					smooth: true, //是否平滑
					showAllSymbol: true,
					symbol: 'circle',
					symbolSize: 12,
					lineStyle: {
						color: '#00b3f4',
					},
					itemStyle: {
						color: 'white',
						borderColor: '#00b3f4',
						borderWidth: 1,
						shadowColor: '#00b3f4',
						shadowBlur: 10,
					},
					areaStyle: {
						normal: {
							color: new window.echarts.graphic.LinearGradient(
								0,
								0,
								0,
								1,
								[
									{
										offset: 0,
										color: 'rgba(0,179,244,0.3)',
									},
									{
										offset: 1,
										color: 'rgba(0,179,244,0)',
									},
								],
								false
							),
							shadowColor: 'rgba(0,179,244, 0.9)',
							shadowBlur: 20,
						},
					},
					data: props.data?.y,
				},
			],
		}
	}

	useEffect(() => {
		const myChart = window.echarts.init(document.getElementById(props.id))

		myChart.setOption(getBaseOption())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.data])

	return <div id={props.id} style={{ height: '100%' }} />
}

export default SingleLineChart
