import React from 'react'
import { ContentLayout, IScrollBoard, PuffLoading } from 'components'

const BeehiveList = ({ loading, noData, data }) => {

	return (
		<ContentLayout title="蜂场蜂箱" icon="ic_fengxiang">
			{loading ? (
				<div className="content-layout-loading">
					<PuffLoading></PuffLoading>
				</div>
			) : noData ? (
				<div className="no-data">暂无蜂箱蜂场</div>
			) : (
						<IScrollBoard
							showDian={false}
							name="beehive"
							align={['center', 'center', 'center', 'center', 'center', 'center', 'center']}
							header={['蜂箱编号', '温度(℃)', '湿度(%)', '重量(Kg)', '进出量(只)', '频率(Hz)', '状态']}
							data={data}
						/>
					)}
		</ContentLayout>
	)
}

export default BeehiveList
