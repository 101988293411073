import React, { useState } from 'react'
import $$ from 'cmn-utils'
import { useHistory } from 'react-router-dom'
import adCodes from 'jsons/ad_code'
import { useWillMount, Toast } from 'components'
import NectarInfo from './nectar_infomation'
import NectarType from './nectar_type'
import SourceStatistic from '../beefarm_page/source_statistic'
import NectarList from './nectar_list'
import VideoMonitor from '../beefarm_page/video_monitor'
import NectarImages from './nectar_images'
import BeehiveList from '../beefarm_page/beehive_list'
import WeatherInfo from './weather_info'
import CenterMap from './center_map'
import NewsList from './news_list'
import VideoMonitorNew from '../beefarm_page/video_monitor_new'

import './index.less'

const getShockStatusText = status => {
  switch (status) {
    case '1':
      return '震动'
    default:
      return '正常'
  }
}
let mapStack = []
const NectarPage = props => {
  const history = useHistory()
  const [nowArea, setNowArea] = React.useState(mapStack[0])
  const [dataSource, setDataSource] = React.useState(null)
  const [noBeehiveData, setNoBeehiveData] = useState(true)
  const [beehiveLoading, setBeehiveLoading] = useState(true)
  const [videoUrl, setVideoUrl] = useState(null)
  const [beehiveList, setBeehiveList] = useState(null)

  useWillMount(() => {
    const currentArea = $$.getStore('currentArea')
    $$.setStore('currentAreaName', currentArea.areaName)
    $$.setStore('currentAreaCode', currentArea.areaCode)
    $$.setStore('currentAreaLevel', currentArea.layer)
    mapStack = [currentArea.areaName]
  })

  React.useEffect(() => {
    //请求基础数据
    $$.post('/data/nectar/baseInfo', {}).then(res => {
      if (res.code === -2) {
        Toast.warning(res.msg)
        $$.removeStore('username')
        $$.removeStore('token')
        $$.removeStore('accountArea')
        $$.removeStore('currentAreaName')
        $$.removeStore('currentArea')
        $$.removeStore('master')
        history.push('/login')
        return
      }
      if (res.code !== 1) return
      setDataSource(res.data)
    })
    queryFarmData($$.getStore('randomFarmId'))
  }, [])

  const onAreaChange = (areaName, isPop) => {
    if (isPop) {
      if (mapStack.length > 1) {
        mapStack.pop()
      }
    } else {
      if (mapStack[mapStack.length - 1] !== areaName) mapStack.push(areaName)
    }
    $$.setStore('currentAreaName', mapStack[mapStack.length - 1])
    document.getElementById('header-now-area').innerText = mapStack[mapStack.length - 1]
    const code = adCodes[mapStack[mapStack.length - 1]] ?? 100000
    $$.setStore('currentAreaCode', code)
    $$.post('/sys/area/info', { areaCode: code }).then(res => {
      if (res.code !== 1) return
      $$.setStore('currentAreaLevel', res.data.hasOwnProperty('layer') ? res.data.layer : 0)
      setNowArea(mapStack[mapStack.length - 1])
    })
  }

  /// 根据蜂场id查询监控视频和蜂箱列表数据
  const queryFarmData = (farmId) => {
    
    setNoBeehiveData(false)
    setVideoUrl(null)
    $$.post('/beeFarm/data/detail', { farmId }).then(res => {
      if (res.code !== 1) {
        setNoBeehiveData(true)
        setBeehiveLoading(false)
        return
      }
      /// 设置监控地址
      if (res.data != null && res.data.videos!=undefined && res.data.videos.length) {
        // setVideoUrl(`https:${res.data.videos[0].streamAddress.split(':')[1]}.flv`)
        setVideoUrl(res.data.videos[0].streamAddress)
      }
      /// 设置蜂箱列表数据
      let _beehiveList = []
      if (res.data.beehives != null) {
        _beehiveList = res.data.beehives.map(d => [
          `<span style="color: white">${d.base.showNo ?? '-'}</span>`,
          d.dynamic.temperature,
          d.dynamic.humidity,
          (d.dynamic.weight / 1000).toFixed(1),
          d.dynamic.inAndOut,
          d.dynamic.frequency,
          getShockStatusText(d.dynamic.shockStatus),
        ])
      }

      setBeehiveLoading(false)
      if (_beehiveList.length > 0) {
        setBeehiveList(_beehiveList)
      }
    })
  }

  const getSuffix = (url) => {
		const _arr = url.split('.')
		const _lastArr = _arr[_arr.length - 1];
		const _arrSplit = _lastArr.split('?');
		return _arrSplit[0];
	}

  return (<div className="nectar-page">
    <div className="grid-item item-1">
      <NectarInfo data={dataSource}></NectarInfo>
    </div>
    <div className="grid-item item-2">
      <CenterMap nowArea={nowArea} onAreaChange={onAreaChange} onFarmClick={id => {
        queryFarmData(id)
      }} />
    </div>
    <div className="grid-item item-3">
      <NectarType></NectarType>
    </div>
    <div className="grid-item item-4">
      <SourceStatistic></SourceStatistic>
    </div>
    <div className="grid-item item-5">
      <WeatherInfo></WeatherInfo>
    </div>
    <div className="grid-item item-6">
      <NectarList onRandomFarmId={id => {
          $$.setStore('randomFarmId', id)
          queryFarmData(id)
        }}></NectarList>
    </div>
    <div className="grid-item item-7">
      {/* <VideoMonitor url={videoUrl ? videoUrl : dataSource?.videoAddress}></VideoMonitor> */}


      {/* <VideoMonitor url={dataSource && dataSource?.mvVideo && dataSource?.mvVideo.length > 6 ? dataSource?.mvVideo : ""}></VideoMonitor> */}


      {dataSource && getSuffix(dataSource?.mvVideo) == 'flv' ? (
        <VideoMonitor url={dataSource && dataSource?.mvVideo && dataSource?.mvVideo.length > 6 ? dataSource?.mvVideo : ""} isShow={true}></VideoMonitor>
      ) : (
        <VideoMonitorNew url={dataSource && dataSource?.mvVideo && dataSource?.mvVideo.length > 6 ? dataSource?.mvVideo : ""} vid={0}  isShow={true}></VideoMonitorNew>
      )}


    </div>
    <div className="grid-item item-8">
      <BeehiveList loading={beehiveLoading} noData={noBeehiveData} data={beehiveList}></BeehiveList>
    </div>
    <div className="grid-item item-9">
      <NectarImages url={dataSource?.honeySourceShow}></NectarImages>
    </div>
    <div className="grid-item item-10">
      <NewsList></NewsList>
    </div>
  </div>)
}

export default NectarPage