import React from 'react'
import { ContentLayout } from 'components'
import LineChartModal from './line_chart_modal'

import './index.less'

const BeefarmInfomation = props => {
	const [modalVisible, setModalVisible] = React.useState(false)
	const [nowType, setNowType] = React.useState(0)
	const dataSource = props.dataSource

	return (
		<ContentLayout title="蜂场信息" icon="ic_information">
			<div className="beefarm-infomation">
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">普通蜂场:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(0)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.ordinaryFarm ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">智慧蜂场:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(1)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.wisdomFarm ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">普通蜂箱:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(2)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.ordinaryBeehive ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">智能蜂箱:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(3)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.smartBeehive ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">养蜂户:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(4)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.beekeepers ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">总产量:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(5)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">
						{dataSource?.totalOutput ?? 0}
						<span className="unit">吨</span>
					</div>
				</div>
			</div>
			<LineChartModal
				visible={modalVisible}
				typeChange={type => setNowType(type)}
				nowType={nowType}
				onCancel={_ => setModalVisible(false)}
			></LineChartModal>
		</ContentLayout>
	)
}

export default BeefarmInfomation
