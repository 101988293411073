import React, { useState } from 'react'
import $$ from 'cmn-utils'
import bg1 from 'assets/video_cover.jpeg'
import VideoMonitorComponents from 'components/video_monitor_components'


import './video_widget.less'

const VideoNewWidget = props => {
  const [isPlay, setIsPlay] = React.useState(true)
  const [weather, setWeather] = useState([])
  let flvPlayer

  React.useEffect(() => {
    console.log('-------------------');
    console.log(props.monitoringAddress)
    console.log(props)
    if (!props.monitoringAddress || props.monitoringAddress === '') return
    if (props.weather) {
      setWeather(JSON.parse(props.weather).data)
    }
    // queryWeatherInfo()
    setTimeout(() => {
      // const _url = `https:${props.monitoringAddress.split(':')[1]}.flv`
      const _url = props.monitoringAddress

      // play(_url)
    }, 1000)
    return willUnmount
  }, [props.id])

  const willUnmount = () => {
    
    // if (flvPlayer) {
    //   flvPlayer.pause()
    //   flvPlayer.unload()
    //   flvPlayer.detachMediaElement()
    //   flvPlayer.destroy()
    //   flvPlayer = null
    // }
  }

  const queryWeatherInfo = () => {
    if (!props.weatherAddress) return

    $$.get(props.weatherAddress).then(res => {
      if (res.status === 200) {
        setWeather(res.data)
        console.log(props.weatherAddress,res.data)
      }
    })
  }

  const play = url => {
    
    const videoElement = document.getElementById(`video-widget-${props.id}`)
    if (videoElement === null) return
    // if (!flvPlayer) {
    //   flvPlayer = window.flvjs.createPlayer({
    //     type: 'flv',
    //     url,
    //     isLive: true,
    //     hasAudio: false,
    //   })
    //   flvPlayer.attachMediaElement(videoElement)
    //   flvPlayer.on(window.flvjs.Events.ERROR, (error) => {
    //     console.log(error)
    //   })
    // }

    // flvPlayer.load()
  }

  const handleStartPlay = () => {
    setIsPlay(true)
    setTimeout(() => {
      // const _url = `https:${props.monitoringAddress.split(':')[1]}.flv`
      const _url = props.monitoringAddress
      // play(_url)
    }, 300)
  }

  // const getDataValue = (key) => {
  //   return weather != null && weather !=  undefined ? weather.find(w => w.Identifier === key)?.itemValue : '无'
  // }

  return (
    <div className="video-widget">
      <div className="info-wrapper">
        <div className="beefarm-name">{props.farmName}</div>
        <div className="farm-weather-info">
          {/* <span>温度：<i>{getDataValue('Temperature')}</i></span>
          <span>湿度：<i>{getDataValue('Humidity')}</i></span>
          <span>风速：<i>{getDataValue('WindSpeed')}</i></span>
          <span>风向：<i>{getDataValue('WindDirection')}</i></span>
          <span>降水量：<i>{getDataValue('Rainfall')}</i></span>
          <br />
          <span>气压：<i>{getDataValue('Atmosphere')}</i></span>
          <span>光照：<i>{getDataValue('LightLux')}</i></span>
          <span>二氧化碳：<i>{getDataValue('CO2Value')}</i></span> */}
        </div>
      </div>
      <div className="video-wrapper">
        {isPlay ? 
        // <video
        //   id={`video-widget-${props.id}`}
        //   style={{ objectFit: 'fill' }}
        //   width="100%"
        //   height="100%"
        //   controlsList="nodownload"
        //   disablePictureInPicture
        //   controls
        //   muted
        //   autoPlay
        // ></video> 
        <VideoMonitorComponents url={props.monitoringAddress} vid={props.vid}></VideoMonitorComponents>
        : <div className="video-cover" onClick={handleStartPlay}>
            <div className="ic-play" />
            <img src={bg1} alt="" />
          </div>
        }
      </div>
    </div>
  )
}

export default VideoNewWidget