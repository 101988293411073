import React from 'react'
import './content_layout.less'

const ContentLayout = ({ title, onActionClick, children, style, icon, isVideo, isFactory }) => {
  return (
    <div className="ContentLayout" style={style}>
      <div className="layout-header">
        <span className="title">{title}</span>
        <i className={`icon ${icon}`} />
        <i className="decoration"></i>
        {onActionClick &&
          <i
            className={`arrow${isVideo ? ' beefarm-monitor' : (isFactory == void 0 ? '' : (isFactory ? ' factory' : ' factory active'))}`}
            onClick={onActionClick}
          />
        }
      </div>
      {children}
    </div>
  )
}

export default ContentLayout
