import CryptoJS from 'crypto-js';


const enkey = CryptoJS.enc.Utf8.parse("asdfn3mnk7kb5sj9");
const enIv = CryptoJS.enc.Utf8.parse('9dj7as3t');

function EncryptMsg(msg) {
    return CryptoJS.DES.encrypt(msg, enkey, {iv: enIv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}).ciphertext.toString()
}

export default EncryptMsg