/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react'
import { ContentLayout, Toast } from 'components'
import { useHistory } from 'react-router-dom'

const CannnigFactory = props => {
	const [isMonitor, setIsMonitor] = React.useState(false)
	const history = useHistory()
	let flvPlayer = useRef(null)
	var isLoop = false

	const [noVideo, setNoVideo] = React.useState(true)
	// console.log(noVideo)
	React.useEffect(() => {
		if (window.flvjs?.isSupported() && props.url[1] && !flvPlayer?.current) {
			setTimeout(() => {
				var videoElement = document.getElementById('canning_factory_video')
				if (videoElement === null) return
				flvPlayer.current = window.flvjs.createPlayer({
					type: getSuffix(props.url[0]),
					url: props.url[0],
					isLive: true,
					hasAudio: false,
				})
				flvPlayer.current.attachMediaElement(videoElement)
				flvPlayer.current.load()
			}, 1000)
		}

		setNoVideo(false)

	}, [props.url])

	const getSuffix = (url) => {
		const _arr = url.split('.')

		const _lastArr = _arr[_arr.length - 1];
		const _arrSplit = _lastArr.split('?');
		return _arrSplit[_arrSplit.length - 1];

		// return _arr[_arr.length - 1]
	}

	const toggleUrl = (type) => {
		if (!flvPlayer.current) return
		var videoElement = document.getElementById('canning_factory_video')
		const url = isMonitor ? props.url[0] : props.url[1]
		var typeSuffix = getSuffix(url);
		if (typeSuffix != 'flv') {
			isLoop = true
		}
		flvPlayer.current.unload()
		flvPlayer.current.destroy()
		flvPlayer.current = window.flvjs.createPlayer({
			type: typeSuffix,
			url: url,
			isLive: true,
			hasAudio: false,
		})
		flvPlayer.current.attachMediaElement(videoElement)
		flvPlayer.current.load()
		setIsMonitor(!isMonitor)
	}

	return (
		// <ContentLayout title="灌装工厂" isFactory={!isMonitor} icon="ic_gongchang" onActionClick={() => {
		// 	if (!props.url[0] || !props.url[1]) return
		// 	toggleUrl()
		// }}>
		// 	<video
		// 		id="canning_factory_video"
		// 		width="100%"
		// 		height="80%"
		// 		controls
		// 		muted
		// 		autoPlay
		// 		controlsList="nodownload"
		// 		disablePictureInPicture
		// 		style={{ objectFit: 'cover' }}
		// 	></video>
		// </ContentLayout>

		<ContentLayout title="视频" icon="ic_gongchang" onActionClick={() => {
			if (!props.url[0] || !props.url[1]) return
			// toggleUrl()
			
			return 
			// if (noVideo) {
			// 	Toast.warning('暂无监控视频')
			// 	return
			// }

			// history.push({
			// 	pathname: '/video?aaa=3333',
			// 	params: {
			// 		videoUrlString: 'http://liveplay.lanzhou.vcn.ctyun.cn//live/29942024127914914.flv',
			// 	}
			// })
			// window.location.reload()
		}}>
			<video
				id="canning_factory_video"
				width="100%"
				height="80%"
				controls
				muted
				autoPlay
				loop={{isLoop}}
				controlsList="nodownload"
				disablePictureInPicture
				style={{ objectFit: 'cover' }}
			></video>
		</ContentLayout>
	)
}

export default CannnigFactory