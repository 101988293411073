import React from 'react'
import Captcha from 'react-captcha-code'
import { useHistory } from 'react-router-dom'
import $$ from 'cmn-utils'
import { TriangleBorderWrapper, Toast } from 'components'
import logoImg from 'assets/logo.png'
import icon_user from 'assets/login/icon_user.png'
import icon_password from 'assets/login/icon_passkey.png'

import './index.less'
import EncryptMsg from 'components/encrypt'

const LoginPage = props => {
	const [captcha, setCaptcha] = React.useState('')
	const captchaRef = React.useRef()
	const history = useHistory()

	const handleLoginClick = () => {
		var captchaValue = document.getElementById('verify-code').value
		if (captcha !== captchaValue) {
			Toast.error('验证码错误')
			captchaRef.current.refresh()
			return
		}
		// var params = {
		// 	username: document.getElementById('username').value,
		// 	password: document.getElementById('password').value,
		// }

		var params = {
			username: EncryptMsg(document.getElementById('username').value),
			password: EncryptMsg(document.getElementById('password').value),
		}
		$$.post('/login/account/password', params).then(res => {
			if (res.code === 1) {
				$$.setStore('username', res.data.realName)
				$$.setStore('token', res.data.token)
				$$.setStore('accountArea', res.data.currentArea)
				$$.setStore('currentAreaName', res.data.currentArea.areaName)
				$$.setStore('currentAreaCode', res.data.currentArea.areaCode)
				$$.setStore('currentArea', res.data.currentArea)
				$$.setStore('master',res.data.master)
				$$.setStore('currentBaseInfo', res.data.currentBaseInfo)

				// $$.setStore('bigTitle', res.data.currentBaseInfo.title)
				// $$.setStore('bigSubtitle', res.data.currentBaseInfo.subtitle)
				// $$.setStore('bigLogo', res.data.currentBaseInfo.logo)

				history.push('/beefarm')
			} else {
				Toast.error(res.msg)
				captchaRef.current.refresh()
			}
		})
	}

	return (
		<div className="login-page">
			<div className="login-content">
				<div className="title-wrapper">
					{/* <img src={logoImg} alt="" /> */}
					<span className="title">智慧蜂业管理平台</span>
				</div>
				<div className="form">
					<TriangleBorderWrapper login="true" style={{ marginBottom: 20 }}>
						<div className="form-item">
							<img src={icon_user} alt="" className="prefix-icon" />
							<input type="text" autoComplete="off" id="username" placeholder="输入登录账号" />
						</div>
					</TriangleBorderWrapper>
					<TriangleBorderWrapper login="true" style={{ marginBottom: 20 }}>
						<div className="form-item">
							<img src={icon_password} alt="" className="prefix-icon" />
							<input autoComplete="off" id="password" placeholder="输入登录密码" type="password" />
						</div>
					</TriangleBorderWrapper>
					<div className="verify-code">
						<div className="verify-code-input">
							<input id="verify-code" autoComplete="off" placeholder="输入验证码" type="text" maxLength={4} />
						</div>
						<div className="verify-code-img">
							<Captcha bgColor="#2f3242" ref={captchaRef} onChange={captcha => setCaptcha(captcha)}></Captcha>
						</div>
					</div>
					<div className="primary-btn fs-22 text-w7" onClick={handleLoginClick}>
						<span className="content">登录</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LoginPage
