import React from 'react'
import { ContentLayout } from 'components'
import LineChartModal from './line_chart_modal'

import './index.less'

const BeefarmInfomation = props => {
	const [modalVisible, setModalVisible] = React.useState(false)
	const [nowType, setNowType] = React.useState(0)
	const dataSource = props.data

	return (
		<ContentLayout title="蜜源信息" icon="ic_information">
			<div className="beefarm-infomation">
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">蜜源种类:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(0)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.typesOfNectar ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">蜜源区域数:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(1)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.nectarAreas ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">总蜜源面积:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(2)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content" style={{ fontSize: 25 }}>
						{dataSource?.nectarSourceArea ?? 0} <span className="unit">亩</span>
					</div>
					{/* <div className="info-item-content">{dataSource?.nectarSourceArea ?? 0}</div> */}
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">蜂蜜预产量:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(3)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">
						{dataSource?.yield ?? 0} <span className="unit">吨</span>
					</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">可放置蜂箱:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(4)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.beehiveCanBePlaced ?? 0}</div>
				</div>
				<div className="info-item">
					<div className="info-item-header">
						<span className="info-item-header-title">已放置蜂箱:</span>
						<i
							className="action-icon"
							onClick={_ => {
								setNowType(5)
								setModalVisible(true)
							}}
						></i>
					</div>
					<div className="info-item-content">{dataSource?.beehivePlaced ?? 0}</div>
				</div>
			</div>
			<LineChartModal
				visible={modalVisible}
				typeChange={type => setNowType(type)}
				nowType={nowType}
				onCancel={_ => setModalVisible(false)}
			></LineChartModal>
		</ContentLayout>
	)
}

export default BeefarmInfomation
