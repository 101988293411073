import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import request from 'cmn-utils/lib/request'
import config from './config'
// import reportWebVitals from './reportWebVitals'

request.config(config.request)
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
