import React from 'react'
import RcPagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import './index.less'

const Pagination = props => {
  return <RcPagination {...props} className="pagination" />
}

export default Pagination
