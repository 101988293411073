import React, { useEffect, useState } from 'react'
import { ContentLayout, PuffLoading } from 'components'
import './index.less'

const ReportInfoGuide = (props) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!props.data) return

    const myChartGuide = window.echarts.init(document.getElementById('governance-chart-guide'))
    const dg = [
      { name: '总技术指导', value: props.data.technicalSupports, color: '#6ac855' },
      { name: '月技术指导', value: props.data.monthTechnicalSupports, color: '#e9a6ff' },
      { name: '总养蜂服务', value: props.data.beekeepingService, color: '#17c9fc' },
      { name: '月养蜂服务', value: props.data.monthBeekeepingService, color: '#ffb24f' },
      // { name: '欧洲幼虫治理', value: props.data.larvaeRot, color: '#53b5df' },
    ]
    setData(dg)
    myChartGuide.setOption(getOpt(dg))
  }, [props.data])

  const getOpt = (data) => {
    return {
      // color: ['#cccc9a', '#62c491', '#ed727c', '#7368ee', '#53b5df'],
      color: ['#6ac855', '#e9a6ff', '#17c9fc', '#ffb24f'],
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#2590B0',
          },
        },
        axisLabel: {
          color: '#2590B0',
        },
      },
      grid: {
        top: '1%',
        height: '65%',
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          color: '#2590B0',
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#2590B0',
          },
        },
        axisLabel: {
          color: '#2590B0',
        },
      },
      series: data.map((item, index) => {
        return {
          type: "bar",
          name: item.name,
          data: [{
            name: item.name,
            value: item.value
          }]
        }
      })
    }
  }

  // return (<ContentLayout title="aa" icon="ic_laiyuan">
  //   {props.loading ? <div className="content-layout-loading">
  //     <PuffLoading></PuffLoading>
  //   </div> : <div className="governance-wrapper">
  //       <div id='governance-chart-guide' style={{ height: '100%' }}></div>
  //       <div className="chart-legends">
  //         {data?.map(d => <div key={d.name} className="legend-item">
  //           <div className="legend-name">
  //             <i className="symbol" style={{ backgroundColor: d.color }}></i>
  //             <span className="name" style={{ color: d.color }}>{d.name}</span>
  //           </div>
  //           <div className="legend-value">{d.value}</div>
  //         </div>)}
  //       </div>
  //     </div>}
  // </ContentLayout>)


return (
props.loading ? <div className="content-layout-loading">
  <PuffLoading></PuffLoading>
</div> : 
<div className="governance-wrapper-guide">
    <div id='governance-chart-guide' style={{ height: '100%' }}></div>
    
    <div className="chart-legends">
      {data?.map(d => <div key={d.name} className="legend-item">
        <div className="legend-name">
          <i className="symbol" style={{ backgroundColor: d.color }}></i>
          <span className="name" style={{ color: d.color }}>{d.name}</span>
        </div>
        {/* <div className="legend-value">{d.value}</div> */}
      </div>)}
    </div>
  </div>
  )
}

export default ReportInfoGuide