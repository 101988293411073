import React, { useState, useEffect } from 'react'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ContentLayout } from 'components'

import 'swiper/swiper.less'

import './index.less'

SwiperCore.use([Autoplay])

const NectarImages = props => {
  const [imgs, setImgs] = useState([])
  const [noData, setNoData] = useState(true)

  useEffect(() => {
    if (props.url) {
      setNoData(false)
      setImgs(props.url.split(','))
    }
  }, [props.url])

  return (<ContentLayout title='蜜源展示' icon='ic_fyzhanshi'>
    {
      noData ?
        <div className="no-data">暂无蜜源图片</div>
        : <div className="nectar-img">
          <Swiper
            autoplay
            loop={true}
          >
            {imgs.map((img, j) => <SwiperSlide key={j}>
              <img className='swiper-slide-img' src={img} alt="" />
            </SwiperSlide>)}
          </Swiper>
        </div>}
  </ContentLayout>)
}

export default NectarImages