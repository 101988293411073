import React from 'react'
import $$ from 'cmn-utils'
import { ContentLayout, MultiLineChart } from 'components'
import PuffLoader from 'react-spinners/PuffLoader'
import './index.less'

function formatter(number) {
	const numbers = number
		.toString()
		.split('')
		.reverse()
	const segs = []

	while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

	return segs
		.join(',')
		.split('')
		.reverse()
		.join('')
}

const SourceStatistic = props => {
	const [loading, setLoading] = React.useState(true)
	const [dataSource, setDataSource] = React.useState(null)
	const [lineDataSource, setLineDataSource] = React.useState(null)

	React.useEffect(() => {
		$$.post('/beeFarm/data/statistics/traceability', {}).then(res => {
			
			if (res.code !== 1 || res.dataSource === null) {
				setLoading(false)
				return
			}
			if (!res.data.purchase || !res.data.logistics || !res.data.warehousing || !res.data.outStock || !res.data.filling) {
				return
			}
			setDataSource(res.data)
			setLoading(false)
			setLineDataSource({
				unit: 'kg',
				x: res.data.purchase.map(d => d.issue),
				y: [
					{
						name: '产蜜',
						data: res.data.purchase.map(d => d.volume),
					},
					{
						name: '加工',
						data: res.data.logistics.map(d => d.volume),
					},
					{
						name: '入库',
						data: res.data.warehousing.map(d => d.volume),
					},
					{
						name: '销量',
						data: res.data.outStock.map(d => d.volume),
					},
					{
						name: '收益',
						data: res.data.filling.map(d => d.volume),
					},
				],
			})
		})
	}, [])

	return (
		<ContentLayout title="数据统计" icon="ic_statistical" style={{ paddingTop: 0, boxSizing: 'border-box', }}>
			{loading || dataSource === null ? (
				<div className="content-layout-loading">
					<PuffLoader color="#17b7e3" loading={loading}></PuffLoader>
				</div>
			) : (
					<>
						{/* <div className="source-statistic">
							<div className="label-ring">
								<div className="left-labels">
									<div className="label-wrapper">
										<span className="label">发放溯源码:</span>
										<span className="value">
											{formatter(
												dataSource.traceabilityCodeCount === null ? 0 : dataSource.traceabilityCodeCount
											)}
										</span>
									</div>
									<div className="label-wrapper">
										<span className="label">消费扫码:</span>
										<span className="value">
											{formatter(dataSource?.searchCount === null ? 0 : dataSource.searchCount)}
										</span>
									</div>
								</div>
							</div>
						</div> */}
						<MultiLineChart dataSource={lineDataSource}></MultiLineChart>
					</>
				)}
		</ContentLayout>
	)
}

export default SourceStatistic
