import React, { useState, useEffect } from 'react'
import { ContentLayout, PuffLoading } from 'components'
import './index.less'

const GoalProgram = (props) => {

  useEffect(() => {
    if (!props.data) return
    const myChart0 = window.echarts.init(document.getElementById('goal_0'))
    const myChart1 = window.echarts.init(document.getElementById('goal_1'))
    const myChart2 = window.echarts.init(document.getElementById('goal_2'))
    const myChart3 = window.echarts.init(document.getElementById('goal_3'))
    const myChart4 = window.echarts.init(document.getElementById('goal_4'))

    myChart0.setOption(getOpt([props.data.outputValue], '#cccba3', '产值提升'))
    myChart1.setOption(getOpt([props.data.brandPower], '#62c18f', '品牌力提升'))
    myChart2.setOption(getOpt([props.data.beekeepingScale], '#ed7378', '养蜂规模提升'))
    myChart3.setOption(getOpt([props.data.productionIncrease], '#51b0dc', '蜂蜜产量提升'))
    myChart4.setOption(getOpt([props.data.beekeeperIncome], '#726aef', '蜂农收入提升'))
  }, [props.data])

  const getOpt = (data, color, label) => {
    return {
      title: {
        text: data + '%',
        textStyle: {
          color: 'white',
          fontSize: 30
        },
        subtext: label,
        subtextStyle: {
          color: '#517f99',
          fontSize: 18,
          fontWeight: 'bold'
        },
        // itemGap: 20,
        left: 'center',
        top: '30%'
      },
      angleAxis: {
        max: 100,
        clockwise: true, // 逆时针
        // 隐藏刻度线
        show: false
      },
      radiusAxis: {
        type: 'category',
        show: true,
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false,

        },
        axisTick: {
          show: false
        },
      },
      polar: {
        center: ['50%', '50%'],
        radius: '180%' //图形大小
      },
      series: [{
        type: 'bar',
        data,
        showBackground: true,
        backgroundStyle: {
          color: '#1d3240',
        },
        coordinateSystem: 'polar',
        barWidth: 10,
        itemStyle: {
          color
        },
      }]
    }
  }

  return (<ContentLayout title="三年目标规划" icon="ic_laiyuan">
    {props.loading ? <div className="content-layout-loading">
      <PuffLoading></PuffLoading>
    </div> : <div className='goal-chart-wrapper'>
        <div id='goal_0' className='goal-chart-item'></div>
        <div id='goal_1' className='goal-chart-item'></div>
        <div id='goal_2' className='goal-chart-item'></div>
        <div id='goal_3' className='goal-chart-item'></div>
        <div id='goal_4' className='goal-chart-item'></div>
      </div>
    }
  </ContentLayout>)
}

export default GoalProgram