import React from 'react'
import { Route, HashRouter as Router, Switch, Redirect } from 'react-router-dom'
import $$ from 'cmn-utils'
import IndexScreen from './pages/index'
import LoginPage from './pages/login'
import './App.less'

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/login" render={_ => ($$.getStore('username') ? <Redirect to="/" /> : <LoginPage />)} />
				<Route
					path="/"
					render={_ => ($$.getStore('username') ? <IndexScreen /> : <Redirect to="/login" />)}
				/>
			</Switch>
		</Router>
	)
}

export default App
