import React, { useState, useEffect } from 'react'
import $$ from 'cmn-utils'
import { IScrollBoard, ContentLayout, PuffLoading } from 'components'
import NewsTable from 'components/news/table'
import ViewNews from 'components/news/view'
import 'components/news/news_modal.less'

const Index = props => {
  const [loading, setLoading] = useState(true)
  const [viewModalVisible, setViewModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [dataSource, setDataSource] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    $$.post('/news/list', { pageNumber: 1, pageSize: 10, type: props.type }).then(res => {
      setLoading(false)
      if (res.code !== 1) return
      setDataSource(res.data.records)
      // setData(res.data.records.map(d => [`[${d.crtAt.substring(5, 10)}]${d.title}`]))
      setData(res.data.records.map(d => [`${d.title}`]))
    })
  }, [props.area])

  return (<ContentLayout title={props.title} icon={props.icon} onActionClick={() => {
    if (loading) return
    $$.trigger(props.eventName)
  }}>
    {loading ? <div className="content-layout-loading">
      <PuffLoading></PuffLoading>
    </div> : <IScrollBoard
        showDian={true}
        columnWidth={[40]}
        align={['left', 'left']}
        rowNum={5}
        data={data}
        onClick={p => {
          setId(dataSource[p.rowIndex].id)
          setViewModalVisible(true)
        }}
      />}
    <NewsTable {...props} onRowClick={id => {
      setId(id)
      setViewModalVisible(true)
    }}></NewsTable>
    {viewModalVisible && <ViewNews visible={viewModalVisible} id={id} onCancel={_ => setViewModalVisible(false)}></ViewNews>}
  </ContentLayout>)
}

export default Index