import React, { useEffect, useRef } from 'react'
import $$ from 'cmn-utils'
import { CardModal } from 'components'
import InfoModal from './beefarm_info_modal'
import cityCordinates from 'jsons/area_cordinate'
import adCodes from 'jsons/ad_code'

const Index = props => {
	let myChart = useRef(null)
	const [modalVisible, setModalVisible] = React.useState(false)
	const [infoModalVisible, setInfoModalVisible] = React.useState(false)
	const [areaCode, setAreaCode] = React.useState(null)
	const [farmId, setFarmId] = React.useState(null)
	const [modalAreaName, setModalAreaName] = React.useState(props.nowArea)

	/**
	 * 开启热力图
	 * @param {Object} opt
	 * @param {Array} data
	 */
	const mergeHeatMapOption = (opt, data) => {
		const heatMapData = data.map(d => {
			return {
				name: d.name,
				value: cityCordinates[d.name].concat(d.value * 1000),
			}
		})
		const heatMapOption = {
			mapType: 'map',
			top: 'center',
			left: 'center',
			name: '',
			type: 'heatmap',
			coordinateSystem: 'geo',
			blurSize: 20,
			data: heatMapData,
			z: 4,
		}
		const visualMapOption = {
			show: false,
			inRange: {
				// 明黄色
				color: ['#e4d87a'],
			},
		}
		//热力图必须配这个组件
		opt.visualMap = visualMapOption
		opt.series.push(heatMapOption)
		return opt
	}

	/**
	 * 数据点上边的信息窗
	 * @param {Object} opt
	 * @param {Array} data
	 * 	 * @param {Bool} isArea 是否是区县
	 */
	const mergeCardScatterOption = (opt, data, isArea) => {
		data = data.map(d => {
			if (isArea) {
				return d
			}
			if (cityCordinates[d.name]) {
				return {
					name: d.name,
					value: cityCordinates[d.name].concat(d.value),
				}
			}
		})

		const option = {
			name: 'Top 5',
			type: 'scatter',
			coordinateSystem: 'geo',
			geoIndex: 0,
			symbol: 'rect',
			symbolSize: [100, 50],
			symbolOffset: [0, '-90%'],
			label: {
				show: true,
				color: '#fff',
				position: 'inside',
				formatter(params) {
					let arr = ['   ' + params.name + '   ', params.value[2]]
					return arr.join('\n')
				},
				textStyle: {
					lineHeight: 25,
					fontSize: 14,
				},
			},
			itemStyle: {
				color: '#6db1d0', //标志颜色
				opacity: 0.9,
			},
			data,
			showEffectOn: 'render',
			rippleEffect: {
				brushType: 'stroke',
			},
			hoverAnimation: true,
			zlevel: 1,
		}
		opt.series.push(option)
		return opt
	}

	/**
	 * 波纹数据点
	 * @param {Object} opt
	 * @param {Array} data
	 * @param {Bool} isArea 是否是区县
	*/
	const mergeEffectScatterOption = (opt, data, isArea) => {
		const effectScatterData = data.map(d => {
			if (isArea) {
				return d
			}
			if (cityCordinates[d.name]) {
				return {
					name: d.name,
					value: cityCordinates[d.name].concat(d.value),
				}
			}
		})

		const effectScatterOption = {
			type: 'effectScatter',
			coordinateSystem: 'geo',
			geoIndex: 0,
			showEffectOn: 'render',
			rippleEffect: {
				//涟漪特效
				period: 4, //动画时间，值越小速度越快
				brushType: 'fill', //波纹绘制方式 stroke, fill
				scale: 4, //波纹圆环最大限制，值越大波纹越大
			},
			itemStyle: {
				color: '#06DFF9',
				shadowBlur: 10,
				shadowColor: '#333',
			},
			data: effectScatterData,
		}
		opt.series.push(effectScatterOption)
		return opt
	}

	const getBaseMapOption = (areaCode) => {
		const margin = {
			top: !areaCode ? '5%' : '7%',
			bottom: !areaCode ? '-35%' : '5%',
		}

		return {
			tooltip: {
				trigger: 'item',
				backgroundColor: 'rgba(0,0,0,0)',
				padding: 0,
				position: 'top',
				formatter: function (item) {
					if (item.componentSubType === 'effectScatter') {
						let _html = ''
						_html = '<div style="background:#6db1d0;padding:5px 20px;border-radius:10px;opacity:0.9;">' +
							'<span style="display:block">' + item.data.name + '</span>' +
							'<span style="display:inline-block;text-align:center;width:100%">' + item.data.value[2] + '</span>' +
							'</div>'

						return _html
					}
				}
			},
			background: '#18A0C8',
			geo: {
				map: 'map',
				id: 1,
				roam: false,
				...margin,
				label: {
					show: true,
					color: '#ffffff',
				},
				emphasis: {
					label: {
						color: '#ffffff',
					},
					itemStyle: {
						areaColor: '#181d28',
					},
				},
				itemStyle: {
					borderColor: 'rgba(147, 235, 248, 1)',
					borderWidth: 1,
					areaColor: {
						type: 'radial',
						x: 0.5,
						y: 0.5,
						r: 0.8,
						colorStops: [
							{
								offset: 0,
								color: 'rgba(147, 235, 248, 0)', // 0% 处的颜色
							},
							{
								offset: 1,
								color: 'rgba(147, 235, 248, .2)', // 100% 处的颜色
							},
						],
						globalCoord: false, // 缺省为 false
					},
					shadowColor: 'rgba(128, 217, 248, 1)',
					// shadowColor: 'rgba(255, 255, 255, 1)',
					shadowOffsetX: -2,
					shadowOffsetY: 2,
					shadowBlur: 10,
				},
			},
			series: [],
		}
	}

	/**
	 * 获取区县蜂场详情数据
	 * @param {string} districtName 区县名称
	 */
	const getDistrictData = districtName => {
		//区县行政编码
		const adCode = adCodes[districtName]
	}

	/**
	 * 注册地图边界数据
	 */
	const registerMap = (_mapJson) => {
		//打开加载动画
		myChart.current.showLoading({ maskColor: 'transparent', color: '#17b7e3' })
		const _areaCode = $$.getStore('currentAreaCode') === 100000 ? void 0 : $$.getStore('currentAreaCode')
		// const mapJson = require(`jsons/area/${props.nowArea}.json`)

		//注册地图数据
		window.echarts.registerMap('map', _mapJson)
		myChart.current.setOption(getBaseMapOption(_areaCode))

		const isArea = $$.getStore('currentAreaLevel') === 3
		/// 省市
		if (!isArea) {
			$$.post('/beeFarm/data/map', {
				// areaLevel: 1,
				// areaCode: 510000,
				areaLevel: props.nowArea === '全国' ? void 0 : 1,
				areaCode: _areaCode,
			}).then(res => {
				myChart.current.hideLoading()
				if (res.code !== 1) return
				const data = res.data.filter(d => d.farmCount > 0).map(d => ({ name: d.areaName, value: d.farmCount }))
				let opt = mergeEffectScatterOption(getBaseMapOption(_areaCode), data, false)
				// opt = mergeCardScatterOption(opt, data, false)

				myChart.current.setOption(opt)
			})
		} else {
			//区县下的蜂场数据
			$$.post('/beeFarm/data/district', { areaCode: _areaCode }).then(res => {
				myChart.current.hideLoading()
				if (res.code !== 1) return

				const data = res.data.map(d => ({ name: d.name, value: [d.lon, d.lat, d.hivesNumber], farmId: d.id }))
				let opt = mergeEffectScatterOption(getBaseMapOption(_areaCode), data, true)
				// opt = mergeCardScatterOption(opt, data, true)

				myChart.current.setOption(opt)
			})
		}
	}

	useEffect(() => {
		try {
			//初始化
			if (myChart == null || myChart.current == void 0) {
				myChart.current = window.echarts.init(document.getElementById('map'))
				//点击地图区域块
				myChart.current.on('click', params => {
					if (params.componentType === 'geo') {
						props.onAreaChange(params.name, false)
						return
					}
					if (params.componentSubType === 'effectScatter') {
						const isArea = $$.getStore('currentAreaLevel') === 3
						
						if (isArea) {
							setInfoModalVisible(true)
							setFarmId(params.data.farmId)
							props.onFarmClick(params.data.farmId)
						}
					}
				})
				// // 点击了地图外的空白
				myChart.current.getZr().on('click', e => {
					if (!e.target) {
						props.onAreaChange('', true)
					}
				})
			}
			const _areaName = props.nowArea ?? $$.getStore('currentAreaName')
			
			$$.get(process.env.REACT_APP_URL + `/geo/${_areaName}.json`).then(res => {
				registerMap(res)
			})
		} catch (error) {
			console.log("地图error", error)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.nowArea])

	return (
		<>
			<div id="map" style={{ height: '100%' }}></div>
			{modalVisible && <CardModal
				visible={modalVisible}
				areaCode={areaCode}
				areaName={modalAreaName}
				onCancel={_ => setModalVisible(false)}
			></CardModal>}
			<InfoModal
				visible={infoModalVisible}
				farmId={farmId}
				onCancel={_ => {
					setInfoModalVisible(false)
					setFarmId(null)
				}}
			></InfoModal>
		</>
	)
}

export default Index
// const mapBorderOption = {
// 	type: 'map',
// 	map: 'map',
// 	geoIndex: 1,
// 	aspectScale: 0.75,
// 	selectedMode: false,
// 	label: {
// 		show: true,
// 		color: '#ffffff',
// 	},
// 	itemStyle: {
// 		borderColor: 'rgba(147, 235, 248, 1)',
// 		borderWidth: 1,
// 		areaColor: {
// 			type: 'radial',
// 			x: 0.5,
// 			y: 0.5,
// 			r: 0.8,
// 			colorStops: [
// 				{
// 					offset: 0,
// 					color: 'rgba(147, 235, 248, 0)', // 0% 处的颜色
// 				},
// 				{
// 					offset: 1,
// 					color: 'rgba(147, 235, 248, .2)', // 100% 处的颜色
// 				},
// 			],
// 			globalCoord: false, // 缺省为 false
// 		},
// 		shadowColor: 'rgba(128, 217, 248, 1)',
// 		// shadowColor: 'rgba(255, 255, 255, 1)',
// 		shadowOffsetX: -2,
// 		shadowOffsetY: 2,
// 		shadowBlur: 10,
// 	},
// 	emphasis: {
// 		label: {
// 			color: '#ffffff',
// 		},
// 		itemStyle: {
// 			areaColor: '#181d28',
// 		},
// 	},
// }