export default {
  台安县: 210321,
  泰和县: 360826,
  贵阳市: 520100,
  柏乡县: 130524,
  望城区: 430112,
  丰林县: 230724,
  奇台县: 652325,
  二七区: 410103,
  定西市: 621100,
  江州区: 451402,
  兰陵县: 371324,
  龙山区: 220402,
  华坪县: 530723,
  铁西区: 220302,
  漳浦县: 350623,
  钟楼区: 320404,
  迪庆藏族自治州: 533400,
  库伦旗: 150524,
  二连浩特市: 152501,
  昌黎县: 130322,
  云冈区: 140214,
  浦城县: 350722,
  化州市: 440982,
  安新县: 130632,
  遂溪县: 440823,
  江川区: 530403,
  岱岳区: 370911,
  大城县: 131025,
  克孜勒苏柯尔克孜自治州: 653000,
  衡阳市: 430400,
  德江县: 520626,
  景东彝族自治县: 530823,
  招远市: 370685,
  永平县: 532928,
  隆林各族自治县: 451031,
  富宁县: 532628,
  开封市: 410200,
  黄石市: 420200,
  印江土家族苗族自治县: 520625,
  库车市: 652902,
  陵城区: 371403,
  安阳县: 410522,
  华安县: 350629,
  韶山市: 430382,
  柳南区: 450204,
  秦安县: 620522,
  庄浪县: 620825,
  兰坪白族普米族自治县: 533325,
  理县: 513222,
  射洪市: 510981,
  宜春市: 360900,
  辽阳市: 211000,
  贺州市: 451100,
  泗水县: 370831,
  大埔县: 441422,
  荔波县: 522722,
  香格里拉市: 533401,
  新郑市: 410184,
  达日县: 632624,
  将乐县: 350428,
  新会区: 440705,
  舟曲县: 623023,
  吐鲁番市: 650400,
  渑池县: 411221,
  黄陂区: 420116,
  通州区: 320612,
  平度市: 370283,
  禅城区: 440604,
  惠来县: 445224,
  濂溪区: 360402,
  吴中区: 320506,
  唐县: 130627,
  宣化区: 130705,
  龙山县: 433130,
  德钦县: 533422,
  日土县: 542524,
  靖宇县: 220622,
  禹王台区: 410205,
  道里区: 230102,
  长兴县: 330522,
  胡杨河市: 659010,
  岳塘区: 430304,
  湛江市: 440800,
  木里藏族自治县: 513422,
  修武县: 410821,
  大石桥市: 210882,
  通川区: 511702,
  宁都县: 360730,
  曾都区: 421303,
  青龙满族自治县: 130321,
  繁昌县: 340222,
  五指山市: 469001,
  灵石县: 140729,
  河津市: 140882,
  秦皇岛市: 130300,
  汉阳区: 420105,
  港北区: 450802,
  永年区: 130408,
  吉县: 141028,
  平原县: 371426,
  临清市: 371581,
  重庆市: 500000,
  高台县: 620724,
  黄山区: 341003,
  阜平县: 130624,
  集美区: 350211,
  内江市: 511000,
  合山市: 451381,
  山西省: 140000,
  来凤县: 422827,
  维西傈僳族自治县: 533423,
  镇平县: 411324,
  民权县: 411421,
  江汉区: 420103,
  洛阳市: 410300,
  沧源佤族自治县: 530927,
  湛河区: 410411,
  蕲春县: 421126,
  南票区: 211404,
  陈仓区: 610304,
  代县: 140923,
  石楼县: 141126,
  淳化县: 610430,
  息县: 411528,
  东安县: 431122,
  海城区: 450502,
  鼎湖区: 441203,
  资源县: 450329,
  古城区: 530702,
  潞州区: 140403,
  合水县: 621024,
  湘东区: 360313,
  平舆县: 411723,
  鸡东县: 230321,
  太仓市: 320585,
  鄂托克旗: 150624,
  抚松县: 220621,
  新龙县: 513329,
  赤峰市: 150400,
  华宁县: 530424,
  乌苏市: 654202,
  灯塔市: 211081,
  五常市: 230184,
  方山县: 141128,
  靖安县: 360925,
  珠山区: 360203,
  什邡市: 510682,
  青海省: 630000,
  岑溪市: 450481,
  平江县: 430626,
  平桂区: 451103,
  昭通市: 530600,
  上党区: 140404,
  赫山区: 430903,
  唐山市: 130200,
  南靖县: 350627,
  四川省: 510000,
  德格县: 513330,
  余干县: 361127,
  安平县: 131125,
  阿尔山市: 152202,
  个旧市: 532501,
  阳谷县: 371521,
  富川瑶族自治县: 451123,
  周口市: 411600,
  莱山区: 370613,
  潍坊市: 370700,
  仁怀市: 520382,
  罗城仫佬族自治县: 451225,
  文圣区: 211003,
  连江县: 350122,
  龙口市: 370681,
  双湖县: 540630,
  阿图什市: 653001,
  博野县: 130637,
  沈北新区: 210113,
  泗县: 341324,
  安化县: 430923,
  大邑县: 510129,
  大姚县: 532326,
  舒兰市: 220283,
  雨花区: 430111,
  玉屏侗族自治县: 520622,
  崖州区: 460205,
  汪清县: 222424,
  古蔺县: 510525,
  巩义市: 410181,
  洪雅县: 511423,
  巴马瑶族自治县: 451227,
  陈巴尔虎旗: 150725,
  兴国县: 360732,
  香洲区: 440402,
  涪城区: 510703,
  班玛县: 632622,
  江山市: 330881,
  曲水县: 540124,
  霍州市: 141082,
  回民区: 150103,
  甘州区: 620702,
  洛隆县: 540329,
  寿宁县: 350924,
  梓潼县: 510725,
  烈山区: 340604,
  南岔县: 230726,
  薛城区: 370403,
  绵阳市: 510700,
  礼县: 621226,
  大竹县: 511724,
  浏阳市: 430181,
  北川羌族自治县: 510726,
  临潭县: 623021,
  白云鄂博矿区: 150206,
  乐业县: 451028,
  鸡泽县: 130431,
  元江哈尼族彝族傣族自治县: 530428,
  金林区: 230751,
  安达市: 231281,
  遵义市: 520300,
  余庆县: 520329,
  利辛县: 341623,
  美兰区: 460108,
  梁子湖区: 420702,
  桂阳县: 431021,
  辽宁省: 210000,
  富顺县: 510322,
  贡井区: 510303,
  庆阳市: 621000,
  合川区: 500117,
  魏县: 130434,
  蕉岭县: 441427,
  宁阳县: 370921,
  台前县: 410927,
  互助土族自治县: 630223,
  曲靖市: 530300,
  云城区: 445302,
  翔安区: 350213,
  德城区: 371402,
  马关县: 532625,
  东洲区: 210403,
  玛曲县: 623025,
  瑶海区: 340102,
  南岗区: 230103,
  温州市: 330300,
  岚山区: 371103,
  宣威市: 530381,
  息烽县: 520122,
  古冶区: 130204,
  红塔区: 530402,
  京山市: 420882,
  五华区: 530102,
  广宁县: 441223,
  平邑县: 371326,
  洛川县: 610629,
  嘉陵区: 511304,
  松阳县: 331124,
  莒县: 371122,
  苍梧县: 450421,
  江华瑶族自治县: 431129,
  东昌府区: 371502,
  宿州市: 341300,
  安塞区: 610603,
  石家庄市: 130100,
  南华县: 532324,
  安国市: 130683,
  本溪满族自治县: 210521,
  吉隆县: 540234,
  始兴县: 440222,
  松北区: 230109,
  新安县: 410323,
  祁县: 140727,
  长乐区: 350112,
  东丽区: 120110,
  乐安县: 361025,
  鄂尔多斯市: 150600,
  海西蒙古族藏族自治州: 632800,
  吉水县: 360822,
  海晏县: 632223,
  若羌县: 652824,
  永泰县: 350125,
  宜良县: 530125,
  银州区: 211202,
  多伦县: 152531,
  兴文县: 511528,
  霍邱县: 341522,
  麻山区: 230307,
  蓬安县: 511323,
  广饶县: 370523,
  阳曲县: 140122,
  浠水县: 421125,
  振兴区: 210603,
  武侯区: 510107,
  林甸县: 230623,
  安泽县: 141026,
  农安县: 220122,
  泉山区: 320311,
  太白县: 610331,
  井陉县: 130121,
  响水县: 320921,
  新华区: 410402,
  筠连县: 511527,
  三原县: 610422,
  叶集区: 341504,
  红山区: 150402,
  高州市: 440981,
  松桃苗族自治县: 520628,
  榆阳区: 610802,
  坡头区: 440804,
  五华县: 441424,
  涟源市: 431382,
  武鸣区: 450110,
  广安区: 511602,
  长汀县: 350821,
  临川区: 361002,
  赛罕区: 150105,
  开化县: 330824,
  茅箭区: 420302,
  淄博市: 370300,
  津南区: 120112,
  盐城市: 320900,
  海盐县: 330424,
  莲都区: 331102,
  绩溪县: 341824,
  丰城市: 360981,
  天全县: 511825,
  下花园区: 130706,
  桑日县: 540523,
  上栗县: 360322,
  正阳县: 411724,
  任泽区: 130505,
  黄平县: 522622,
  西固区: 620104,
  东港区: 371102,
  莘县: 371522,
  西藏自治区: 540000,
  准格尔旗: 150622,
  深圳市: 440300,
  滨海新区: 120116,
  精河县: 652722,
  建邺区: 320105,
  乡宁县: 141029,
  嫩江市: 231183,
  开平区: 130205,
  闵行区: 310112,
  哈巴河县: 654324,
  夹江县: 511126,
  镇宁布依族苗族自治县: 520423,
  东宝区: 420802,
  阿拉善右旗: 152922,
  保定市: 130600,
  克拉玛依市: 650200,
  逊克县: 231123,
  绥棱县: 231226,
  丹凤县: 611022,
  巴州区: 511902,
  嵩县: 410325,
  北流市: 450981,
  孝南区: 420902,
  麻栗坡县: 532624,
  东丰县: 220421,
  新宁县: 430528,
  全南县: 360729,
  周宁县: 350925,
  鱼峰区: 450203,
  阿拉尔市: 659002,
  雨山区: 340504,
  西双版纳傣族自治州: 532800,
  岳麓区: 430104,
  黔西南布依族苗族自治州: 522300,
  朝阳市: 211300,
  丰满区: 220211,
  开阳县: 520121,
  东光县: 130923,
  忻城县: 451321,
  淅川县: 411326,
  冀州区: 131103,
  平武县: 510727,
  北辰区: 120113,
  沂水县: 371323,
  融安县: 450224,
  水磨沟区: 650105,
  平城区: 140213,
  政和县: 350725,
  常宁市: 430482,
  福清市: 350181,
  讷河市: 230281,
  牟定县: 532323,
  革吉县: 542525,
  汉阴县: 610921,
  紫阳县: 610924,
  白云区: 520113,
  细河区: 210911,
  西夏区: 640105,
  阆中市: 511381,
  太康县: 411627,
  宁化县: 350424,
  栾川县: 410324,
  赤壁市: 421281,
  韩城市: 610581,
  榆社县: 140721,
  石门县: 430726,
  托克逊县: 650422,
  绥宁县: 430527,
  永新县: 360830,
  嘉定区: 310114,
  包河区: 340111,
  康定市: 513301,
  昌乐县: 370725,
  仪陇县: 511324,
  富民县: 530124,
  合江县: 510522,
  曹妃甸区: 130209,
  新县: 411523,
  常德市: 430700,
  钦北区: 450703,
  登封市: 410185,
  五通桥区: 511112,
  临高县: 469024,
  河西区: 120103,
  连城县: 350825,
  渝中区: 500103,
  蓝田县: 610122,
  武强县: 131123,
  万荣县: 140822,
  根河市: 150785,
  隆昌市: 511083,
  安宁区: 620105,
  邹城市: 370883,
  平桥区: 411503,
  于都县: 360731,
  龙安区: 410506,
  昌江区: 360202,
  鄂温克族自治旗: 150724,
  堆龙德庆区: 540103,
  达拉特旗: 150621,
  荆州市: 421000,
  金山区: 310116,
  东西湖区: 420112,
  硚口区: 420104,
  蒙阴县: 371328,
  南丰县: 361023,
  合浦县: 450521,
  张家港市: 320582,
  江城区: 441702,
  五峰土家族自治县: 420529,
  潜江市: 429005,
  陕州区: 411203,
  和平县: 441624,
  新洲区: 420117,
  东莞市: 441900,
  肥西县: 340123,
  栾城区: 130111,
  东阳市: 330783,
  龙马潭区: 510504,
  宁国市: 341881,
  依安县: 230223,
  寻甸回族彝族自治县: 530129,
  伊州区: 650502,
  怀柔区: 110116,
  潍城区: 370702,
  西吉县: 640422,
  曲江区: 440205,
  栖霞市: 370686,
  汝州市: 410482,
  垣曲县: 140827,
  广丰区: 361103,
  黎平县: 522631,
  潞城区: 140406,
  乌兰浩特市: 152201,
  荣昌区: 500153,
  广宗县: 130531,
  梅里斯达斡尔族区: 230208,
  洛江区: 350504,
  洪湖市: 421083,
  灵台县: 620822,
  新丰县: 440233,
  资溪县: 361028,
  崇义县: 360725,
  福安市: 350981,
  卫滨区: 410703,
  双城区: 230113,
  龙岗区: 440307,
  延边朝鲜族自治州: 222400,
  永靖县: 622923,
  南陵县: 340223,
  郯城县: 371322,
  揭东区: 445203,
  石台县: 341722,
  贵池区: 341702,
  洛浦县: 653224,
  仁化县: 440224,
  屯留区: 140405,
  长海县: 210224,
  双峰县: 431321,
  防城区: 450603,
  江海区: 440704,
  长武县: 610428,
  北戴河区: 130304,
  通海县: 530423,
  浮梁县: 360222,
  青州市: 370781,
  织金县: 520524,
  东昌区: 220502,
  奈曼旗: 150525,
  连云区: 320703,
  邕宁区: 450109,
  卫辉市: 410781,
  谢家集区: 340404,
  巴里坤哈萨克自治县: 650521,
  中牟县: 410122,
  武汉市: 420100,
  迎江区: 340802,
  黄石港区: 420202,
  滨湖区: 320211,
  顺庆区: 511302,
  芮城县: 140830,
  廊坊市: 131000,
  泰安市: 370900,
  樊城区: 420606,
  永清县: 131023,
  绥中县: 211421,
  灌云县: 320723,
  临夏回族自治州: 622900,
  张掖市: 620700,
  伊犁哈萨克自治州: 654000,
  固阳县: 150222,
  临潼区: 610115,
  阜宁县: 320923,
  水城县: 520221,
  崆峒区: 620802,
  甘井子区: 210211,
  琼结县: 540524,
  合肥市: 340100,
  资兴市: 431081,
  金塔县: 620921,
  兴城市: 211481,
  习水县: 520330,
  蒲县: 141033,
  临桂区: 450312,
  无为市: 340281,
  叶城县: 653126,
  盐都区: 320903,
  龙华区: 460106,
  宿松县: 340826,
  绥德县: 610826,
  博罗县: 441322,
  全椒县: 341124,
  通城县: 421222,
  福绵区: 450903,
  铁力市: 230781,
  青山湖区: 360111,
  松山区: 150404,
  壶关县: 140427,
  景宁畲族自治县: 331127,
  自贡市: 510300,
  皇姑区: 210105,
  黄岛区: 370211,
  丰都县: 500230,
  武陵源区: 430811,
  惠州市: 441300,
  当阳市: 420582,
  黄南藏族自治州: 632300,
  高邮市: 321084,
  南康区: 360703,
  城东区: 630102,
  肃北蒙古族自治县: 620923,
  延津县: 410726,
  南漳县: 420624,
  济宁市: 370800,
  泸县: 510521,
  科尔沁左翼后旗: 150522,
  东胜区: 150602,
  兖州区: 370812,
  双台子区: 211102,
  鄂伦春自治旗: 150723,
  龙泉市: 331181,
  延吉市: 222401,
  繁峙县: 140924,
  安龙县: 522328,
  清新区: 441803,
  太谷区: 140703,
  新津区: 510118,
  宜城市: 420684,
  虹口区: 310109,
  桑植县: 430822,
  未央区: 610112,
  和平区: 210102,
  昂昂溪区: 230205,
  祁门县: 341024,
  太和县: 341222,
  信丰县: 360722,
  西乌珠穆沁旗: 152526,
  河口区: 370503,
  墨脱县: 540423,
  厦门市: 350200,
  滨江区: 330108,
  芙蓉区: 430102,
  怀仁市: 140681,
  柘城县: 411424,
  成安县: 130424,
  阳明区: 231003,
  伊宁市: 654002,
  宁陕县: 610923,
  亭湖区: 320902,
  银海区: 450503,
  汇川区: 520303,
  大足区: 500111,
  孝昌县: 420921,
  麦积区: 620503,
  前郭尔罗斯蒙古族自治县: 220721,
  浦口区: 320111,
  郫都区: 510117,
  蒙山县: 450423,
  吴兴区: 330502,
  尉氏县: 410223,
  红岗区: 230605,
  治多县: 632724,
  东兰县: 451224,
  商南县: 611023,
  昌邑区: 220202,
  岗巴县: 540237,
  铜陵市: 340700,
  广东省: 440000,
  盘龙区: 530103,
  托里县: 654224,
  容城县: 130629,
  上高县: 360923,
  陇县: 610327,
  东兴区: 511011,
  漾濞彝族自治县: 532922,
  温泉县: 652723,
  左贡县: 540327,
  南岸区: 500108,
  献县: 130929,
  道外区: 230104,
  荷塘区: 430202,
  环江毛南族自治县: 451226,
  平顶山市: 410400,
  刚察县: 632224,
  阿克陶县: 653022,
  正安县: 520324,
  德惠市: 220183,
  浑南区: 210112,
  普陀区: 330903,
  潮州市: 445100,
  师宗县: 530323,
  东至县: 341721,
  海沧区: 350205,
  石泉县: 610922,
  固镇县: 340323,
  泾县: 341823,
  南阳市: 411300,
  闽侯县: 350121,
  金平区: 440511,
  涵江区: 350303,
  北林区: 231202,
  红安县: 421122,
  措美县: 540526,
  正宁县: 621025,
  巢湖市: 340181,
  北仑区: 330206,
  佳木斯市: 230800,
  越秀区: 440104,
  于田县: 653226,
  铜鼓县: 360926,
  漯河市: 411100,
  绥化市: 231200,
  海珠区: 440105,
  青县: 130922,
  南平市: 350700,
  宜君县: 610222,
  汤阴县: 410523,
  成华区: 510108,
  沈河区: 210103,
  巴音郭楞蒙古自治州: 652800,
  朔城区: 140602,
  猇亭区: 420505,
  锦屏县: 522628,
  李沧区: 370213,
  沁源县: 140431,
  西林县: 451030,
  和硕县: 652828,
  阳朔县: 450321,
  泸水市: 533301,
  延长县: 610621,
  台山市: 440781,
  江源区: 220605,
  利川市: 422802,
  晋宁区: 530115,
  通江县: 511921,
  定日县: 540223,
  琅琊区: 341102,
  山阳区: 410811,
  蓝山县: 431127,
  济南市: 370100,
  武冈市: 430581,
  晴隆县: 522324,
  富锦市: 230882,
  巴中市: 511900,
  苏州市: 320500,
  大荔县: 610523,
  定安县: 469021,
  盐田区: 440308,
  积石山保安族东乡族撒拉族自治县: 622927,
  淳安县: 330127,
  峨山彝族自治县: 530426,
  灵武市: 640181,
  福海县: 654323,
  马村区: 410804,
  凌云县: 451027,
  开鲁县: 150523,
  华容区: 420703,
  嘉黎县: 540621,
  魏都区: 411002,
  三门峡市: 411200,
  永宁县: 640121,
  枣强县: 131121,
  伊川县: 410329,
  南岳区: 430412,
  牙克石市: 150782,
  呼伦贝尔市: 150700,
  翼城县: 141022,
  金阳县: 513430,
  鹤山市: 440784,
  和田县: 653221,
  广陵区: 321002,
  阿合奇县: 653023,
  阿勒泰市: 654301,
  华阴市: 610582,
  崇明区: 310151,
  清徐县: 140121,
  集宁区: 150902,
  霸州市: 131081,
  锡林郭勒盟: 152500,
  蒸湘区: 430408,
  广南县: 532627,
  黄龙县: 610631,
  上蔡县: 411722,
  临武县: 431025,
  南丹县: 451221,
  太和区: 210711,
  路桥区: 331004,
  梁溪区: 320213,
  红旗区: 410702,
  凤台县: 340421,
  盘山县: 211122,
  太湖县: 340825,
  乌拉特中旗: 150824,
  鲁甸县: 530621,
  邵阳市: 430500,
  隆回县: 430524,
  天峨县: 451222,
  莲花县: 360321,
  应县: 140622,
  吉州区: 360802,
  长阳土家族自治县: 420528,
  旌阳区: 510603,
  城中区: 630103,
  中山市: 442000,
  华容县: 430623,
  英吉沙县: 653123,
  城步苗族自治县: 430529,
  东方市: 469007,
  临邑县: 371424,
  从江县: 522633,
  东明县: 371728,
  梁园区: 411402,
  乌拉特前旗: 150823,
  乌达区: 150304,
  南谯区: 341103,
  高唐县: 371526,
  嘉善县: 330421,
  临城县: 130522,
  玉州区: 450902,
  岢岚县: 140929,
  平房区: 230108,
  上饶市: 361100,
  蔡甸区: 420114,
  舟山市: 330900,
  顺平县: 130636,
  芜湖市: 340200,
  行唐县: 130125,
  邯山区: 130402,
  东湖区: 360102,
  巴楚县: 653130,
  鲁山县: 410423,
  围场满族蒙古族自治县: 130828,
  夏邑县: 411426,
  竹山县: 420323,
  金东区: 330703,
  崂山区: 370212,
  昆都仑区: 150203,
  偏关县: 140932,
  赤坎区: 440802,
  宁洱哈尼族彝族自治县: 530821,
  错那县: 540530,
  仙游县: 350322,
  富拉尔基区: 230206,
  瓯海区: 330304,
  洋县: 610723,
  寒亭区: 370703,
  吉安市: 360800,
  怀安县: 130728,
  特克斯县: 654027,
  河池市: 451200,
  襄都区: 130502,
  全州县: 450324,
  梁河县: 533122,
  安福县: 360829,
  和静县: 652827,
  南乐县: 410923,
  色达县: 513333,
  金安区: 341502,
  三明市: 350400,
  简阳市: 510185,
  克拉玛依区: 650203,
  泰来县: 230224,
  光泽县: 350723,
  宁县: 621026,
  秦州区: 620502,
  天峻县: 632823,
  阜南县: 341225,
  兴山区: 230407,
  徐水区: 130609,
  湟源县: 630123,
  永丰县: 360825,
  忻州市: 140900,
  托克托县: 150122,
  鹤峰县: 422828,
  新兴区: 230902,
  井冈山市: 360881,
  德兴市: 361181,
  上街区: 410106,
  新绛县: 140825,
  巨鹿县: 130529,
  南湖区: 330402,
  柳江区: 450206,
  坪山区: 440310,
  固安县: 131022,
  永德县: 530923,
  南和区: 130506,
  博山区: 370304,
  金秀瑶族自治县: 451324,
  万源市: 511781,
  民勤县: 620621,
  澳门特别行政区: 820000,
  东宁市: 231086,
  衢江区: 330803,
  乌审旗: 150626,
  睢县: 411422,
  文安县: 131026,
  东区: 510402,
  海淀区: 110108,
  桂林市: 450300,
  港南区: 450803,
  同安区: 350212,
  屏山县: 511529,
  商水县: 411623,
  广昌县: 361030,
  乌当区: 520112,
  尖扎县: 632322,
  吴江区: 320509,
  西山区: 530112,
  德州市: 371400,
  清江浦区: 320812,
  罗平县: 530324,
  泸西县: 532527,
  界首市: 341282,
  大通回族土族自治县: 630121,
  永定区: 430802,
  南浔区: 330503,
  阿坝县: 513231,
  泾阳县: 610423,
  福建省: 350000,
  榆林市: 610800,
  喜德县: 513432,
  兰考县: 410225,
  鹤城区: 431202,
  丹徒区: 321112,
  泉港区: 350505,
  滕州市: 370481,
  卓尼县: 623022,
  醴陵市: 430281,
  兴庆区: 640104,
  嘉禾县: 431024,
  共和县: 632521,
  莎车县: 653125,
  承德县: 130821,
  兴山县: 420526,
  亳州市: 341600,
  滦州市: 130284,
  安次区: 131002,
  应城市: 420981,
  苏尼特右旗: 152524,
  磐石市: 220284,
  泸溪县: 433122,
  麒麟区: 530302,
  泽库县: 632323,
  信阳市: 411500,
  巫溪县: 500238,
  名山区: 511803,
  邵东市: 430582,
  随州市: 421300,
  高碑店市: 130684,
  南关区: 220102,
  定南县: 360728,
  武进区: 320412,
  银川市: 640100,
  双桥区: 130802,
  松滋市: 421087,
  沽源县: 130724,
  封丘县: 410727,
  乐平市: 360281,
  道孚县: 513326,
  永顺县: 433127,
  明水县: 231225,
  云溪区: 430603,
  阳城县: 140522,
  鱼台县: 370827,
  莆田市: 350300,
  石阡县: 520623,
  同仁市: 632301,
  东川区: 530113,
  崇川区: 320602,
  怀宁县: 340822,
  凤泉区: 410704,
  天津市: 120000,
  东乡区: 361003,
  吕梁市: 141100,
  丰泽区: 350503,
  疏勒县: 653122,
  宾阳县: 450126,
  翠屏区: 511502,
  遂平县: 411728,
  冷水滩区: 431103,
  句容市: 321183,
  新泰市: 370982,
  安定区: 621102,
  光山县: 411522,
  石屏县: 532525,
  若尔盖县: 513232,
  山阴县: 140621,
  镇康县: 530924,
  皮山县: 653223,
  九台区: 220113,
  白河县: 610929,
  乌什县: 652927,
  新昌县: 330624,
  汉南区: 420113,
  都安瑶族自治县: 451228,
  平湖市: 330482,
  山阳县: 611024,
  三江侗族自治县: 450226,
  阿克苏市: 652901,
  桂东县: 431027,
  伊通满族自治县: 220323,
  北海市: 450500,
  洪江市: 431281,
  凤凰县: 433123,
  冕宁县: 513433,
  白银区: 620402,
  南昌县: 360121,
  尖草坪区: 140108,
  渠县: 511725,
  九江市: 360400,
  电白区: 440904,
  宽甸满族自治县: 210624,
  上城区: 330102,
  吉木乃县: 654326,
  驿城区: 411702,
  华州区: 610503,
  眉山市: 511400,
  瑞安市: 330381,
  易门县: 530425,
  娄烦县: 140123,
  南明区: 520102,
  渝水区: 360502,
  开远市: 532502,
  高青县: 370322,
  新疆维吾尔自治区: 650000,
  贡觉县: 540322,
  良庆区: 450108,
  晋安区: 350111,
  磴口县: 150822,
  调兵山市: 211281,
  灞桥区: 610111,
  宜阳县: 410327,
  凤城市: 210682,
  张家界市: 430800,
  二道区: 220105,
  义安区: 340706,
  市北区: 370203,
  蜀山区: 340104,
  茫崖市: 632803,
  西青区: 120111,
  五大连池市: 231182,
  定陶区: 371703,
  美姑县: 513436,
  盐山县: 130925,
  田林县: 451029,
  邳州市: 320382,
  桃山区: 230903,
  七台河市: 230900,
  榕江县: 522632,
  海城市: 210381,
  璧山区: 500120,
  安阳市: 410500,
  徐汇区: 310104,
  浔阳区: 360403,
  头屯河区: 650106,
  海拉尔区: 150702,
  龙港区: 211403,
  万柏林区: 140109,
  集贤县: 230521,
  越城区: 330602,
  高淳区: 320118,
  天长市: 341181,
  且末县: 652825,
  札达县: 542522,
  仪征市: 321081,
  宁陵县: 411423,
  藤县: 450422,
  贡嘎县: 540522,
  铁锋区: 230204,
  桥东区: 130702,
  船营区: 220204,
  泰兴市: 321283,
  临西县: 130535,
  梨树区: 230305,
  武都区: 621202,
  交城县: 141122,
  阿巴嘎旗: 152522,
  天水市: 620500,
  华池县: 621023,
  文登区: 371003,
  岚县: 141127,
  东风区: 230805,
  噶尔县: 542523,
  湖里区: 350206,
  丽水市: 331100,
  台江县: 522630,
  青河县: 654325,
  郸城县: 411625,
  芦溪县: 360323,
  上犹县: 360724,
  鸠江区: 340207,
  楚雄彝族自治州: 532300,
  屯昌县: 469022,
  沅陵县: 431222,
  石棉县: 511824,
  原阳县: 410725,
  禹州市: 411081,
  新巴尔虎左旗: 150726,
  抚宁区: 130306,
  铜梁区: 500151,
  丘北县: 532626,
  高阳县: 130628,
  潼南区: 500152,
  颍泉区: 341204,
  沂源县: 370323,
  建阳区: 350703,
  四子王旗: 150929,
  祥云县: 532923,
  新乡县: 410721,
  迭部县: 623024,
  榆中县: 620123,
  自流井区: 510302,
  同德县: 632522,
  肥城市: 370983,
  云霄县: 350622,
  温县: 410825,
  安义县: 360123,
  修文县: 520123,
  长白朝鲜族自治县: 220623,
  贵州省: 520000,
  孟村回族自治县: 130930,
  章丘区: 370114,
  留坝县: 610729,
  鼎城区: 430703,
  齐河县: 371425,
  漳县: 621125,
  清苑区: 130608,
  长寿区: 500115,
  镇安县: 611025,
  罗山县: 411521,
  临湘市: 430682,
  肇庆市: 441200,
  张湾区: 420303,
  兴仁市: 522302,
  景泰县: 620423,
  罗甸县: 522728,
  凌河区: 210703,
  来安县: 341122,
  白城市: 220800,
  碾子山区: 230207,
  金坛区: 320413,
  单县: 371722,
  天桥区: 370105,
  察哈尔右翼后旗: 150928,
  敖汉旗: 150430,
  七星区: 450305,
  花垣县: 433124,
  那曲市: 540600,
  呼图壁县: 652323,
  喀喇沁左翼蒙古族自治县: 211324,
  阳高县: 140221,
  达川区: 511703,
  川汇区: 411602,
  宁津县: 371422,
  临澧县: 430724,
  中江县: 510623,
  太子河区: 211011,
  融水苗族自治县: 450225,
  罗庄区: 371311,
  镇坪县: 610927,
  昂仁县: 540226,
  黄岩区: 331003,
  濠江区: 440512,
  龙陵县: 530523,
  土默特左旗: 150121,
  庐山市: 360483,
  乐山市: 511100,
  贞丰县: 522325,
  成都市: 510100,
  桓仁满族自治县: 210522,
  隆尧县: 130525,
  南县: 430921,
  中原区: 410102,
  沾益区: 530303,
  乐至县: 512022,
  水富市: 530681,
  岳阳市: 430600,
  阳原县: 130727,
  和布克赛尔蒙古自治县: 654226,
  社旗县: 411327,
  揭阳市: 445200,
  中站区: 410803,
  米林县: 540422,
  翁源县: 440229,
  六合区: 320116,
  卢龙县: 130324,
  西平县: 411721,
  苏仙区: 431003,
  酉阳土家族苗族自治县: 500242,
  钢城区: 370117,
  泸州市: 510500,
  永登县: 620121,
  白沙黎族自治县: 469025,
  阎良区: 610114,
  黔东南苗族侗族自治州: 522600,
  如东县: 320623,
  曲沃县: 141021,
  伽师县: 653129,
  乌海市: 150300,
  龙游县: 330825,
  丰县: 320321,
  延川县: 610622,
  科尔沁左翼中旗: 150521,
  正定县: 130123,
  三门县: 331022,
  轮台县: 652822,
  睢阳区: 411403,
  铜川市: 610200,
  莱阳市: 370682,
  林周县: 540121,
  徽县: 621227,
  无极县: 130130,
  贵港市: 450800,
  滨海县: 320922,
  孟津县: 410322,
  滨城区: 371602,
  清远市: 441800,
  黄陵县: 610632,
  罗田县: 421123,
  玉树藏族自治州: 632700,
  仓山区: 350104,
  南川区: 500119,
  贾汪区: 320305,
  安岳县: 512021,
  象州县: 451322,
  周至县: 610124,
  庆城县: 621021,
  乐清市: 330382,
  台江区: 350103,
  康县: 621224,
  东营区: 370502,
  湘潭县: 430321,
  宁南县: 513427,
  曹县: 371721,
  新和县: 652925,
  永福县: 450326,
  鹤岗市: 230400,
  南通市: 320600,
  牡丹区: 371702,
  青白江区: 510113,
  顺河回族区: 410203,
  开福区: 430105,
  漳州市: 350600,
  新兴县: 445321,
  上思县: 450621,
  峨边彝族自治县: 511132,
  万山区: 520603,
  长春市: 220100,
  临县: 141124,
  德保县: 451024,
  卫东区: 410403,
  武平县: 350824,
  泌阳县: 411726,
  榕城区: 445202,
  奎屯市: 654003,
  石龙区: 410404,
  桂平市: 450881,
  宝山区: 310113,
  洛扎县: 540527,
  同心县: 640324,
  稷山县: 140824,
  泊头市: 130981,
  旌德县: 341825,
  大观区: 340803,
  抚远市: 230883,
  新民市: 210181,
  汶上县: 370830,
  上杭县: 350823,
  乳山市: 371083,
  文水县: 141121,
  淮阴区: 320804,
  延安市: 610600,
  平远县: 441426,
  兴业县: 450924,
  当涂县: 340521,
  福州市: 350100,
  马尾区: 350105,
  南安市: 350583,
  钟山县: 451122,
  朔州市: 140600,
  陆川县: 450922,
  兴安区: 230405,
  循化撒拉族自治县: 630225,
  淮阳区: 411603,
  三都水族自治县: 522732,
  瓦房店市: 210281,
  定兴县: 130626,
  弓长岭区: 211005,
  监利县: 421023,
  绿春县: 532531,
  察隅县: 540425,
  南开区: 120104,
  龙州县: 451423,
  西安区: 231005,
  庐江县: 340124,
  汝阳县: 410326,
  宝塔区: 610602,
  龙潭区: 220203,
  五原县: 150821,
  工农区: 230403,
  藁城区: 130109,
  安陆市: 420982,
  奉化区: 330213,
  三台县: 510722,
  新建区: 360112,
  龙川县: 441622,
  阿瓦提县: 652928,
  湖口县: 360429,
  惠山区: 320206,
  黄州区: 421102,
  波密县: 540424,
  河源市: 441600,
  华蓥市: 511681,
  章贡区: 360702,
  永昌县: 620321,
  保亭黎族苗族自治县: 469029,
  临沭县: 371329,
  曲麻莱县: 632726,
  怀化市: 431200,
  伍家岗区: 420503,
  独山县: 522726,
  涡阳县: 341621,
  玉树市: 632701,
  润州区: 321111,
  安庆市: 340800,
  神池县: 140927,
  青云谱区: 360104,
  恩阳区: 511903,
  青阳县: 341723,
  临夏县: 622921,
  兴安县: 450325,
  稻城县: 513337,
  木兰县: 230127,
  定结县: 540231,
  竞秀区: 130602,
  垦利区: 370505,
  酒泉市: 620900,
  荥经县: 511822,
  横山区: 610803,
  江夏区: 420115,
  绥江县: 530626,
  吴起县: 610626,
  灵山县: 450721,
  湖州市: 330500,
  上虞区: 330604,
  康保县: 130723,
  鸡冠区: 230302,
  万年县: 361129,
  城关区: 620102,
  青田县: 331121,
  金水区: 410105,
  雁山区: 450311,
  通河县: 230128,
  南皮县: 130927,
  大武口区: 640202,
  卢氏县: 411224,
  隆子县: 540529,
  宝鸡市: 610300,
  庆云县: 371423,
  惠阳区: 441303,
  永胜县: 530722,
  大渡口区: 500104,
  弋阳县: 361126,
  和顺县: 140723,
  锡林浩特市: 152502,
  偃师市: 410381,
  林芝市: 540400,
  龙子湖区: 340302,
  大同区: 230606,
  西岗区: 210203,
  安徽省: 340000,
  尚义县: 130725,
  永善县: 530625,
  建平县: 211322,
  下城区: 330103,
  麻江县: 522635,
  镇赉县: 220821,
  乌拉特后旗: 150825,
  田阳区: 451003,
  卡若区: 540302,
  冷水江市: 431381,
  合作市: 623001,
  泰州市: 321200,
  兴宁区: 450102,
  库尔勒市: 652801,
  常州市: 320400,
  红河哈尼族彝族自治州: 532500,
  鲅鱼圈区: 210804,
  柳城县: 450222,
  东海县: 320722,
  当雄县: 540122,
  东城区: 110101,
  江口县: 520621,
  祁连县: 632222,
  南宁市: 450100,
  垫江县: 500231,
  涞水县: 130623,
  青羊区: 510105,
  色尼区: 540602,
  肇州县: 230621,
  平罗县: 640221,
  信州区: 361102,
  清河门区: 210905,
  丰顺县: 441423,
  郎溪县: 341821,
  资阳区: 430902,
  高港区: 321203,
  云浮市: 445300,
  南江县: 511922,
  端州区: 441202,
  凤阳县: 341126,
  西乡塘区: 450107,
  依兰县: 230123,
  扬中市: 321182,
  杭锦后旗: 150826,
  开平市: 440783,
  芒市: 533103,
  榆次区: 140702,
  雁塔区: 610113,
  商州区: 611002,
  乌恰县: 653024,
  荥阳市: 410182,
  连南瑶族自治县: 441826,
  渭城区: 610404,
  巍山彝族回族自治县: 532927,
  鹿城区: 330302,
  费县: 371325,
  吉首市: 433101,
  昆山市: 320583,
  宁波市: 330200,
  岚皋县: 610925,
  鹿寨县: 450223,
  江达县: 540321,
  蕉城区: 350902,
  呈贡区: 530114,
  镶黄旗: 152528,
  香港特别行政区: 810000,
  博爱县: 410822,
  普安县: 522323,
  渭南市: 610500,
  丹棱县: 511424,
  杜尔伯特蒙古族自治县: 230624,
  内丘县: 130523,
  阳西县: 441721,
  广灵县: 140223,
  奉节县: 500236,
  丰南区: 130207,
  道县: 431124,
  都江堰市: 510181,
  大名县: 130425,
  新蔡县: 411729,
  郁南县: 445322,
  梨树县: 220322,
  岷县: 621126,
  淮滨县: 411527,
  清丰县: 410922,
  青山区: 420107,
  镇沅彝族哈尼族拉祜族自治县: 530825,
  东港市: 210681,
  瑞丽市: 533102,
  西华县: 411622,
  昌图县: 211224,
  石林彝族自治县: 530126,
  宽城满族自治县: 130827,
  南城县: 361021,
  独山子区: 650202,
  顺德区: 440606,
  乌鲁木齐市: 650100,
  任丘市: 130982,
  临淄区: 370305,
  武清区: 120114,
  宣汉县: 511722,
  建瓯市: 350783,
  岑巩县: 522626,
  大新县: 451424,
  嘉峪关市: 620200,
  洪山区: 420111,
  古田县: 350922,
  弥勒市: 532504,
  长沙县: 430121,
  岳池县: 511621,
  尉犁县: 652823,
  茄子河区: 230904,
  涟水县: 320826,
  长垣市: 410783,
  奉新县: 360921,
  孙吴县: 231124,
  陵水黎族自治县: 469028,
  京口区: 321102,
  久治县: 632625,
  丰宁满族自治县: 130826,
  申扎县: 540625,
  衡水市: 131100,
  连州市: 441882,
  满城区: 130607,
  湘桥区: 445102,
  吴桥县: 130928,
  浑江区: 220602,
  牧野区: 410711,
  柴桑区: 360404,
  墨江哈尼族自治县: 530822,
  灵丘县: 140224,
  广德市: 341882,
  雅江县: 513325,
  滴道区: 230304,
  安宁市: 530181,
  睢宁县: 320324,
  天河区: 440106,
  永川区: 500118,
  峨眉山市: 511181,
  哈尔滨市: 230100,
  龙湖区: 440507,
  宣州区: 341802,
  洮南市: 220881,
  富源县: 530325,
  义县: 210727,
  新市区: 650104,
  永兴县: 431023,
  溧阳市: 320481,
  蓬江区: 440703,
  大田县: 350425,
  类乌齐县: 540323,
  广元市: 510800,
  屏南县: 350923,
  通榆县: 220822,
  禹城市: 371482,
  咸安区: 421202,
  克山县: 230229,
  霞山区: 440803,
  邯郸市: 130400,
  雨湖区: 430302,
  清流县: 350423,
  武山县: 620524,
  浦北县: 450722,
  兰西县: 231222,
  横县: 450127,
  潮阳区: 440513,
  余江区: 360603,
  乡城县: 513336,
  梅河口市: 220581,
  甘泉县: 610627,
  鄱阳县: 361128,
  黔江区: 500114,
  文山市: 532601,
  松潘县: 513224,
  浮山县: 141027,
  梁平区: 500155,
  公安县: 421022,
  柘荣县: 350926,
  随县: 421321,
  鄂托克前旗: 150623,
  佛坪县: 610730,
  安州区: 510705,
  天涯区: 460204,
  宿迁市: 321300,
  阜阳市: 341200,
  黔南布依族苗族自治州: 522700,
  越西县: 513434,
  昌吉回族自治州: 652300,
  乳源瑶族自治县: 440232,
  瀍河回族区: 410304,
  云梦县: 420923,
  抚顺县: 210421,
  荆门市: 420800,
  苍南县: 330327,
  韶关市: 440200,
  临河区: 150802,
  望都县: 130631,
  新宾满族自治县: 210422,
  黄山市: 341000,
  莲湖区: 610104,
  大冶市: 420281,
  容县: 450921,
  阜新市: 210900,
  唐河县: 411328,
  静宁县: 620826,
  广安市: 511600,
  潘集区: 340406,
  永和县: 141032,
  铁山区: 420205,
  河北区: 120105,
  新城区: 610102,
  五寨县: 140928,
  边坝县: 540330,
  彭水苗族土家族自治县: 500243,
  雅安市: 511800,
  漠河市: 232701,
  于洪区: 210114,
  北京市: 110000,
  凤山县: 451223,
  岭东区: 230503,
  巴彦淖尔市: 150800,
  大化瑶族自治县: 451229,
  凌海市: 210781,
  郧西县: 420322,
  霍山县: 341525,
  东源县: 441625,
  清水县: 620521,
  寿光市: 370783,
  南海区: 440605,
  廉江市: 440881,
  临江市: 220681,
  朗县: 540426,
  鄂城区: 420704,
  古交市: 140181,
  仲巴县: 540232,
  瑞昌市: 360481,
  梅江区: 441402,
  武威市: 620600,
  射阳县: 320924,
  巧家县: 530622,
  台儿庄区: 370405,
  额尔古纳市: 150784,
  盘锦市: 211100,
  浈江区: 440204,
  江西省: 360000,
  安乡县: 430721,
  静安区: 310106,
  蠡县: 130635,
  公主岭市: 220184,
  攀枝花市: 510400,
  尼木县: 540123,
  南充市: 511300,
  渭源县: 621123,
  高坪区: 511303,
  肇东市: 231282,
  溧水区: 320117,
  盐津县: 530623,
  芝罘区: 370602,
  嵊州市: 330683,
  宾县: 230125,
  中卫市: 640500,
  钦南区: 450702,
  宝清县: 230523,
  忠县: 500233,
  宁明县: 451422,
  普定县: 520422,
  夏河县: 623027,
  金牛区: 510106,
  凤庆县: 530921,
  坊子区: 370704,
  相城区: 320507,
  扶绥县: 451421,
  钟山区: 520201,
  南涧彝族自治县: 532926,
  诸城市: 370782,
  磐安县: 330727,
  芗城区: 350602,
  石河子市: 659001,
  商丘市: 411400,
  曲松县: 540525,
  北塔区: 430511,
  易县: 130633,
  肇源县: 230622,
  南宫市: 130581,
  芒康县: 540328,
  德化县: 350526,
  敦化市: 222403,
  远安县: 420525,
  吴忠市: 640300,
  扶风县: 610324,
  建安区: 411003,
  会理县: 513425,
  红寺堡区: 640303,
  义马市: 411281,
  宜都市: 420581,
  左权县: 140722,
  湘西土家族苗族自治州: 433100,
  伊春市: 230700,
  耒阳市: 430481,
  陕西省: 610000,
  浙江省: 330000,
  陇川县: 533124,
  侯马市: 141081,
  梁山县: 370832,
  商城县: 411524,
  深州市: 131182,
  西塞山区: 420203,
  巩留县: 654024,
  桐梓县: 520322,
  绛县: 140826,
  宝安区: 440306,
  包头市: 150200,
  石拐区: 150205,
  任城区: 370811,
  雨城区: 511802,
  柯坪县: 652929,
  昭化区: 510811,
  东乡族自治县: 622926,
  兰州市: 620100,
  九寨沟县: 513225,
  永安市: 350481,
  枝江市: 420583,
  延寿县: 230129,
  临泉县: 341221,
  灌阳县: 450327,
  大祥区: 430503,
  金州区: 210213,
  潼关县: 610522,
  龙南市: 360783,
  内黄县: 410527,
  前进区: 230804,
  秭归县: 420527,
  密山市: 230382,
  高平市: 140581,
  鄞州区: 330212,
  宁江区: 220702,
  鹤壁市: 410600,
  西畴县: 532623,
  萨尔图区: 230602,
  金川区: 620302,
  方城县: 411322,
  陆丰市: 441581,
  大通区: 340402,
  双滦区: 130803,
  万安县: 360828,
  信宜市: 440983,
  阳江市: 441700,
  和县: 340523,
  滦平县: 130824,
  兴县: 141123,
  光明区: 440311,
  蛟河市: 220281,
  平阴县: 370124,
  务川仡佬族苗族自治县: 520326,
  解放区: 410802,
  比如县: 540622,
  交口县: 141130,
  黟县: 341023,
  定海区: 330902,
  伊金霍洛旗: 150627,
  古丈县: 433126,
  龙城区: 211303,
  浪卡子县: 540531,
  平阳县: 330326,
  茂县: 513223,
  微山县: 370826,
  耀州区: 610204,
  虎丘区: 320505,
  拜城县: 652926,
  博白县: 450923,
  共青城市: 360482,
  会同县: 431225,
  铜山区: 320312,
  望奎县: 231221,
  范县: 410926,
  古塔区: 210702,
  常熟市: 320581,
  和田市: 653201,
  石渠县: 513332,
  洪泽区: 320813,
  彬州市: 610482,
  金乡县: 370828,
  炉霍县: 513327,
  元阳县: 532528,
  市南区: 370202,
  尧都区: 141002,
  祥符区: 410212,
  获嘉县: 410724,
  祁阳县: 431121,
  小金县: 513227,
  周村区: 370306,
  金川县: 513226,
  化德县: 150922,
  商洛市: 611000,
  炎陵县: 430225,
  市中区: 511102,
  乐陵市: 371481,
  碌曲县: 623026,
  辉县市: 410782,
  宜丰县: 360924,
  安丘市: 370784,
  理塘县: 513334,
  新源县: 654025,
  本溪市: 210500,
  雨花台区: 320114,
  滨州市: 371600,
  白塔区: 211002,
  乾安县: 220723,
  龙港市: 330383,
  叙永县: 510524,
  岫岩满族自治县: 210323,
  宝应县: 321023,
  开州区: 500154,
  城厢区: 350302,
  离石区: 141102,
  萍乡市: 360300,
  淮上区: 340311,
  富县: 610628,
  台湾省: 710000,
  海门市: 320684,
  定襄县: 140921,
  凌源市: 211382,
  桐乡市: 330483,
  莫力达瓦达斡尔族自治旗: 150722,
  玛沁县: 632621,
  施甸县: 530521,
  丛台区: 130403,
  贡山独龙族怒族自治县: 533324,
  秀屿区: 350305,
  黔西县: 520522,
  红花岗区: 520302,
  高明区: 440608,
  孝义市: 141181,
  峄城区: 370404,
  塔河县: 232722,
  邓州市: 411381,
  黑山县: 210726,
  怒江傈僳族自治州: 533300,
  犍为县: 511123,
  浚县: 410621,
  赫章县: 520527,
  尼玛县: 540629,
  旬邑县: 610429,
  临海市: 331082,
  龙岩市: 350800,
  丽江市: 530700,
  山丹县: 620725,
  建宁县: 350430,
  镇原县: 621027,
  方正县: 230124,
  舞钢市: 410481,
  图木舒克市: 659003,
  潢川县: 411526,
  甘孜藏族自治州: 513300,
  汤旺县: 230723,
  康平县: 210123,
  衢州市: 330800,
  永春县: 350525,
  历下区: 370102,
  大方县: 520521,
  兴安盟: 152200,
  石狮市: 350581,
  高昌区: 650402,
  达坂城区: 650107,
  皋兰县: 620122,
  天台县: 331023,
  池州市: 341700,
  英山县: 421124,
  濮阳县: 410928,
  明光市: 341182,
  镇巴县: 610728,
  绥芬河市: 231081,
  山东省: 370000,
  黄埔区: 440112,
  蔚县: 130726,
  格尔木市: 632801,
  鄯善县: 650421,
  疏附县: 653121,
  江北区: 500105,
  颍上县: 341226,
  江干区: 330104,
  南木林县: 540221,
  房山区: 110111,
  安康市: 610900,
  尖山区: 230502,
  进贤县: 360124,
  玛纳斯县: 652324,
  宝坻区: 120115,
  南沙区: 440115,
  塔城市: 654201,
  南溪区: 511503,
  千山区: 210311,
  隆阳区: 530502,
  扬州市: 321000,
  穆棱市: 231085,
  谷城县: 420625,
  麻章区: 440811,
  黄浦区: 310101,
  可克达拉市: 659008,
  珠晖区: 430405,
  崇州市: 510184,
  和龙市: 222406,
  门头沟区: 110109,
  南昌市: 360100,
  牡丹江市: 231000,
  顺义区: 110113,
  平山县: 130131,
  隆化县: 130825,
  成武县: 371723,
  六盘水市: 520200,
  加查县: 540528,
  平凉市: 620800,
  鹿邑县: 411628,
  高陵区: 610117,
  裕安区: 341503,
  番禺区: 440113,
  荔城区: 350304,
  广水市: 421381,
  爱民区: 231004,
  朝天区: 510812,
  武陟县: 410823,
  德庆县: 441226,
  汾阳市: 141182,
  法库县: 210124,
  柳州市: 450200,
  环翠区: 371002,
  宝丰县: 410421,
  兴宾区: 451302,
  蒲江县: 510131,
  沈丘县: 411624,
  西盟佤族自治县: 530829,
  通许县: 410222,
  台州市: 331000,
  克东县: 230230,
  都匀市: 522701,
  安源区: 360302,
  承德市: 130800,
  望花区: 210404,
  淮安区: 320803,
  澄海区: 440515,
  平陆县: 140829,
  慈溪市: 330282,
  额济纳旗: 152923,
  集安市: 220582,
  大宁县: 141030,
  海曙区: 330203,
  固始县: 411525,
  吉木萨尔县: 652327,
  佳县: 610828,
  峰峰矿区: 130406,
  青神县: 511425,
  普兰县: 542521,
  姜堰区: 321204,
  孟连傣族拉祜族佤族自治县: 530827,
  勐海县: 532822,
  康马县: 540230,
  姑苏区: 320508,
  两当县: 621228,
  茌平区: 371503,
  白银市: 620400,
  弥渡县: 532925,
  剑川县: 532931,
  新邵县: 430522,
  石景山区: 110107,
  西充县: 511325,
  左云县: 140226,
  汉中市: 610700,
  拉萨市: 540100,
  新乐市: 130184,
  济源市: 419001,
  站前区: 210802,
  秀山土家族苗族自治县: 500241,
  乐昌市: 440281,
  保靖县: 433125,
  泽州县: 140525,
  丰镇市: 150981,
  龙江县: 230221,
  新余市: 360500,
  文成县: 330328,
  咸丰县: 422826,
  即墨区: 370215,
  云阳县: 500235,
  东兴市: 450681,
  昌邑市: 370786,
  乃东区: 540502,
  利通区: 640302,
  磁县: 130427,
  丹阳市: 321181,
  防城港市: 450600,
  昭平县: 451121,
  阿荣旗: 150721,
  兴隆台区: 211103,
  兴化市: 321281,
  隰县: 141031,
  甘南藏族自治州: 623000,
  河北省: 130000,
  和田地区: 653200,
  千阳县: 610328,
  铁东区: 220303,
  松江区: 310117,
  兴隆县: 130822,
  荔湾区: 440103,
  河间市: 130984,
  路北区: 130203,
  康巴什区: 150603,
  饶河县: 230524,
  西陵区: 420502,
  梅县区: 441403,
  开江县: 511723,
  麦盖提县: 653127,
  甘孜县: 513328,
  大悟县: 420922,
  惠安县: 350521,
  西丰县: 211223,
  建华区: 230203,
  临汾市: 141000,
  裕华区: 130108,
  闽清县: 350124,
  福鼎市: 350982,
  雷州市: 440882,
  九龙县: 513324,
  喀什地区: 653100,
  兴平市: 610481,
  三亚市: 460200,
  巴彦县: 230126,
  槐荫区: 370104,
  顺昌县: 350721,
  裕民县: 654225,
  惠东县: 441323,
  安顺市: 520400,
  宜黄县: 361026,
  谯城区: 341602,
  齐齐哈尔市: 230200,
  湖滨区: 411202,
  宁蒗彝族自治县: 530724,
  乌尔禾区: 650205,
  黄骅市: 130983,
  东辽县: 220422,
  金湖县: 320831,
  歙县: 341021,
  十堰市: 420300,
  田东县: 451022,
  海口市: 460100,
  昭觉县: 513431,
  嘉兴市: 330400,
  仁和区: 510411,
  江城哈尼族彝族自治县: 530826,
  莒南县: 371327,
  上林县: 450125,
  成县: 621221,
  沙河市: 130582,
  颍东区: 341203,
  阳春市: 441781,
  龙湾区: 330303,
  海棠区: 460202,
  八宿县: 540326,
  宁安市: 231084,
  芦淞区: 430203,
  王益区: 610202,
  桦南县: 230822,
  铁山港区: 450512,
  夷陵区: 420506,
  吴川市: 440883,
  盐湖区: 140802,
  昭阳区: 530602,
  上海市: 310000,
  临沂市: 371300,
  红原县: 513233,
  沙洋县: 420822,
  志丹县: 610625,
  清镇市: 520181,
  乌鲁木齐县: 650121,
  江都区: 321012,
  新邱区: 210903,
  惠民县: 371621,
  武宁县: 360423,
  前锋区: 511603,
  得荣县: 513338,
  平山区: 210502,
  叙州区: 511504,
  满洲里市: 150781,
  高县: 511525,
  莲池区: 130606,
  呼兰区: 230111,
  普宁市: 445281,
  杜集区: 340602,
  杭锦旗: 150625,
  汉寿县: 430722,
  福田区: 440304,
  江门市: 440700,
  大箐山县: 230725,
  兴和县: 150924,
  隆安县: 450123,
  蒲城县: 610526,
  黑河市: 231100,
  通道侗族自治县: 431230,
  都兰县: 632822,
  云龙区: 320303,
  金华市: 330700,
  大连市: 210200,
  陇南市: 621200,
  乐亭县: 130225,
  忻府区: 140902,
  嘉荫县: 230722,
  甘肃省: 620000,
  清原满族自治县: 210423,
  金湾区: 440404,
  庄河市: 210283,
  涿州市: 130681,
  博湖县: 652829,
  金寨县: 341524,
  达尔罕茂明安联合旗: 150223,
  聊城市: 371500,
  清城区: 441802,
  锦江区: 510104,
  平坝区: 520403,
  襄阳市: 420600,
  萧县: 341322,
  新荣区: 140212,
  神木市: 610881,
  恭城瑶族自治县: 450332,
  长沙市: 430100,
  永寿县: 610426,
  马龙区: 530304,
  普格县: 513428,
  西和县: 621225,
  泰山区: 370902,
  红桥区: 120106,
  苏尼特左旗: 152523,
  长葛市: 411082,
  晋中市: 140700,
  慈利县: 430821,
  河南蒙古族自治县: 632324,
  原平市: 140981,
  昔阳县: 140724,
  无棣县: 371623,
  济阳区: 370115,
  乌兰察布市: 150900,
  汉源县: 511823,
  营口市: 210800,
  关岭布依族苗族自治县: 520424,
  宾川县: 532924,
  海林市: 231083,
  右江区: 451002,
  博尔塔拉蒙古自治州: 652700,
  洪洞县: 141024,
  东营市: 370500,
  云龙县: 532929,
  长泰县: 350625,
  茂南区: 440902,
  城西区: 630104,
  合阳县: 610524,
  玉环市: 331083,
  郧阳区: 420304,
  怀集县: 441224,
  宽城区: 220103,
  绿园区: 220106,
  常山县: 330822,
  罗湖区: 440303,
  察雅县: 540325,
  元谋县: 532328,
  武定县: 532329,
  宜州区: 451203,
  遂昌县: 331123,
  涞源县: 130630,
  博兴县: 371625,
  屏边苗族自治县: 532523,
  广汉市: 510681,
  徽州区: 341004,
  谢通门县: 540227,
  新野县: 411329,
  武陵区: 430702,
  盖州市: 210881,
  新乡市: 410700,
  内乡县: 411325,
  赵县: 130133,
  邵武市: 350781,
  富蕴县: 654322,
  奉贤区: 310120,
  崇仁县: 361024,
  平利县: 610926,
  乾县: 610424,
  玉龙纳西族自治县: 530721,
  柳河县: 220524,
  宁海县: 330226,
  宁德市: 350900,
  邛崃市: 510183,
  潮安区: 445103,
  贺兰县: 640122,
  分宜县: 360521,
  靖江市: 321282,
  博望区: 340506,
  召陵区: 411104,
  通山县: 421224,
  江孜县: 540222,
  湘潭市: 430300,
  天等县: 451425,
  德清县: 330521,
  新密市: 410183,
  三穗县: 522624,
  城区: 441502,
  西秀区: 520402,
  河东区: 371312,
  西乡县: 610724,
  东河区: 150202,
  克什克腾旗: 150425,
  盂县: 140322,
  张家口市: 130700,
  许昌市: 411000,
  大厂回族自治县: 131028,
  汕尾市: 441500,
  月湖区: 360602,
  迁安市: 130283,
  宁武县: 140925,
  太仆寺旗: 152527,
  旅顺口区: 210212,
  南澳县: 440523,
  崇左市: 451400,
  通辽市: 150500,
  大东区: 210104,
  三沙市: 460300,
  彭州市: 510182,
  顺城区: 210411,
  大安区: 510304,
  肥东县: 340122,
  玉泉区: 150104,
  霍林郭勒市: 150581,
  琼海市: 469002,
  雄县: 130638,
  资中县: 511025,
  龙胜各族自治县: 450328,
  纳雍县: 520525,
  耿马傣族佤族自治县: 530926,
  荣成市: 371082,
  明溪县: 350421,
  平塘县: 522727,
  佛山市: 440600,
  子洲县: 610831,
  双阳区: 220112,
  龙沙区: 230202,
  布拖县: 513429,
  三山区: 340208,
  攸县: 430223,
  阿拉山口市: 652702,
  米东区: 650109,
  临猗县: 140821,
  聂荣县: 540623,
  宁城县: 150429,
  井陉矿区: 130107,
  阿城区: 230112,
  澄城县: 610525,
  文峰区: 410502,
  凉州区: 620602,
  二道江区: 220503,
  萝北县: 230421,
  察哈尔右翼前旗: 150926,
  营山县: 511322,
  长清区: 370113,
  隆德县: 640423,
  江阳区: 510502,
  饶阳县: 131124,
  湘乡市: 430381,
  德令哈市: 632802,
  太原市: 140100,
  咸阳市: 610400,
  花溪区: 520111,
  鞍山市: 210300,
  西工区: 410303,
  化隆回族自治县: 630224,
  右玉县: 140623,
  永康市: 330784,
  缙云县: 331122,
  禄丰县: 532331,
  察哈尔右翼中旗: 150927,
  汝南县: 411727,
  肃宁县: 130926,
  桐城市: 340881,
  略阳县: 610727,
  塔什库尔干塔吉克自治县: 653131,
  赣县区: 360704,
  封开县: 441225,
  龙亭区: 410202,
  禄劝彝族苗族自治县: 530128,
  浑源县: 140225,
  饶平县: 445122,
  姚安县: 532325,
  巫山县: 500237,
  平鲁区: 140603,
  勃利县: 230921,
  嵊泗县: 330922,
  印台区: 610203,
  环县: 621022,
  达州市: 511700,
  徐州市: 320300,
  湖北省: 420000,
  阿拉善盟: 152900,
  凤翔县: 610322,
  云安区: 445303,
  长子县: 140428,
  冠县: 371525,
  镇海区: 330211,
  道真仡佬族苗族自治县: 520325,
  五家渠市: 659004,
  休宁县: 341022,
  虎林市: 230381,
  八步区: 451102,
  崇信县: 620823,
  兴宁市: 441481,
  纳溪区: 510503,
  连平县: 441623,
  巴塘县: 513335,
  五河县: 340322,
  绵竹市: 510683,
  万全区: 130708,
  德安县: 360426,
  蓬溪县: 510921,
  邹平市: 371681,
  三河市: 131082,
  鄂州市: 420700,
  望谟县: 522326,
  五台县: 140922,
  朝阳县: 211321,
  临沧市: 530900,
  温宿县: 652922,
  林西县: 150424,
  利津县: 370522,
  山海关区: 130303,
  靖州苗族侗族自治县: 431229,
  丹东市: 210600,
  北碚区: 500109,
  向阳区: 230803,
  天山区: 650102,
  扶沟县: 411621,
  香河县: 131024,
  察布查尔锡伯自治县: 654022,
  黎川县: 361022,
  启东市: 320681,
  马山县: 450124,
  新河县: 130530,
  凯里市: 522601,
  河曲县: 140930,
  泾川县: 620821,
  临泽县: 620723,
  和政县: 622925,
  玄武区: 320102,
  泗阳县: 321323,
  莱州市: 370683,
  海陵区: 321202,
  保德县: 140931,
  胶州市: 370281,
  靖边县: 610824,
  梅州市: 441400,
  汤原县: 230828,
  遵化市: 130281,
  静乐县: 140926,
  朝阳区: 220104,
  巴青县: 540628,
  建昌县: 211422,
  都昌县: 360428,
  沙坡头区: 640502,
  海丰县: 441521,
  闻喜县: 140823,
  呼和浩特市: 150100,
  佛冈县: 441821,
  和林格尔县: 150123,
  让胡路区: 230604,
  阜城县: 131128,
  平果市: 451082,
  辛集市: 130181,
  安溪县: 350524,
  元宝区: 210602,
  武隆区: 500156,
  民丰县: 653227,
  从化区: 440117,
  铜仁市: 520600,
  碑林区: 610103,
  神农架林区: 429021,
  金口河区: 511113,
  海港区: 130302,
  铁岭县: 211221,
  禹会区: 340304,
  壤塘县: 513230,
  儋州市: 460400,
  屯溪区: 341002,
  日喀则市: 540200,
  扎赉诺尔区: 150703,
  潮南区: 440514,
  涪陵区: 500102,
  通化县: 220521,
  桦甸市: 220282,
  双鸭山市: 230500,
  大丰区: 320904,
  元氏县: 130132,
  友好区: 230719,
  镇雄县: 530627,
  尼勒克县: 654028,
  揭西县: 445222,
  云县: 530922,
  友谊县: 230522,
  阳信县: 371622,
  沁县: 140430,
  桃江县: 430922,
  大理白族自治州: 532900,
  涉县: 130426,
  梧州市: 450400,
  连山区: 211402,
  芦山县: 511826,
  张家川回族自治县: 620525,
  渌口区: 430212,
  琼中黎族苗族自治县: 469030,
  宛城区: 411302,
  南郑区: 610703,
  阿克塞哈萨克族自治县: 620924,
  福山区: 370611,
  元宝山区: 150403,
  宜秀区: 340811,
  舞阳县: 411121,
  府谷县: 610822,
  宁晋县: 130528,
  西湖区: 360103,
  丹江口市: 420381,
  双塔区: 211302,
  拱墅区: 330105,
  阜康市: 652302,
  宜川县: 610630,
  玛多县: 632626,
  大理市: 532901,
  岳西县: 340828,
  大同市: 140200,
  海南藏族自治州: 632500,
  惠水县: 522731,
  鄄城县: 371726,
  斗门区: 440403,
  柳林县: 141125,
  南召县: 411321,
  蚌埠市: 340300,
  寿县: 340422,
  麻阳苗族自治县: 431226,
  余杭区: 330110,
  昆明市: 530100,
  吴堡县: 610829,
  晋源区: 140110,
  昌平区: 110114,
  正镶白旗: 152529,
  尚志市: 230183,
  汕头市: 440500,
  扶余市: 220781,
  辽源市: 220400,
  曲阳县: 130634,
  会泽县: 530326,
  广州市: 440100,
  双柏县: 532322,
  清水河县: 150124,
  秀洲区: 330411,
  海南区: 150303,
  茂名市: 440900,
  巴林右旗: 150423,
  盐池县: 640323,
  双清区: 430502,
  平安区: 630203,
  樟树市: 360982,
  锡山区: 320205,
  毕节市: 520500,
  嵩明县: 530127,
  祁东县: 430426,
  建水县: 532524,
  阿拉善左旗: 152921,
  柞水县: 611026,
  武宣县: 451323,
  双辽市: 220382,
  迎泽区: 140106,
  杏花岭区: 140107,
  西峰区: 621002,
  凉城县: 150925,
  赤城县: 130732,
  普兰店区: 210214,
  亚东县: 540233,
  义乌市: 330782,
  咸宁市: 421200,
  鹤山区: 410602,
  玉田县: 130229,
  宜章县: 431022,
  红古区: 620111,
  南雄市: 440282,
  海北藏族自治州: 632200,
  钟祥市: 420881,
  颍州区: 341202,
  长宁县: 511524,
  南部县: 511321,
  埇桥区: 341302,
  林州市: 410581,
  介休市: 140781,
  杞县: 410221,
  武夷山市: 350782,
  涧西区: 410305,
  鹿泉区: 130110,
  昭苏县: 654026,
  盐源县: 513423,
  八公山区: 340405,
  山城区: 410603,
  北关区: 410503,
  诸暨市: 330681,
  宝兴县: 511827,
  呼玛县: 232721,
  海阳市: 370687,
  遂宁市: 510900,
  阿里地区: 542500,
  普洱市: 530800,
  万州区: 500101,
  江安县: 511523,
  资阳市: 512000,
  长治市: 140400,
  管城回族区: 410104,
  宁夏回族自治区: 640000,
  玉溪市: 530400,
  茶陵县: 430224,
  萨嘎县: 540236,
  桑珠孜区: 540202,
  汉川市: 420984,
  密云区: 110118,
  高邑县: 130127,
  陆河县: 441523,
  平定县: 140321,
  婺城区: 330702,
  景谷傣族彝族自治县: 530824,
  吉阳区: 460203,
  焦作市: 410800,
  洞口县: 430525,
  商河县: 370126,
  牟平区: 370612,
  田家庵区: 340403,
  阿坝藏族羌族自治州: 513200,
  白水县: 610527,
  枣阳市: 420683,
  如皋市: 320682,
  太平区: 210904,
  会东县: 513426,
  门源回族自治县: 632221,
  娄星区: 431302,
  兴海县: 632524,
  焉耆回族自治县: 652826,
  安图县: 222426,
  覃塘区: 450804,
  长安区: 610116,
  江油市: 510781,
  洛龙区: 410311,
  文昌市: 469005,
  内蒙古自治区: 150000,
  岱山县: 330921,
  萨迦县: 540224,
  卓资县: 150921,
  伊美区: 230717,
  甘洛县: 513435,
  山南市: 540500,
  策勒县: 653225,
  阳山县: 441823,
  陇西县: 621122,
  西城区: 110102,
  城子河区: 230306,
  雷山县: 522634,
  泰宁县: 350429,
  宕昌县: 621223,
  淇滨区: 410611,
  万秀区: 450403,
  沭阳县: 321322,
  霞浦县: 350921,
  澧县: 430723,
  百色市: 451000,
  鹤庆县: 532932,
  滦南县: 130224,
  老城区: 410302,
  灵川县: 450323,
  城固县: 610722,
  赣榆区: 320707,
  四方台区: 230505,
  武昌区: 420106,
  永济市: 140881,
  拉孜县: 540225,
  沈阳市: 210100,
  阿勒泰地区: 654300,
  六枝特区: 520203,
  定边县: 610825,
  立山区: 210304,
  双河市: 659007,
  金昌市: 620300,
  临夏市: 622901,
  西市区: 210803,
  灌南县: 320724,
  桃城区: 131102,
  邱县: 130430,
  大兴安岭地区: 232700,
  平顺县: 140425,
  寻乌县: 360734,
  龙凤区: 230603,
  邵阳县: 430523,
  紫云苗族布依族自治县: 520425,
  思南县: 520624,
  丁青县: 540324,
  阜新蒙古族自治县: 210921,
  吉林省: 220000,
  杨陵区: 610403,
  广河县: 622924,
  民和回族土族自治县: 630222,
  黄冈市: 421100,
  天门市: 429006,
  抚顺市: 210400,
  工布江达县: 540421,
  浦东新区: 310115,
  房县: 420325,
  恩平市: 440785,
  丹巴县: 513323,
  平泉市: 130881,
  沾化区: 371603,
  津市市: 430781,
  孝感市: 420900,
  北屯市: 659005,
  西区: 510403,
  榆树市: 220182,
  文山壮族苗族自治州: 532600,
  怀来县: 130730,
  龙文区: 350603,
  昆玉市: 659009,
  渭滨区: 610302,
  平南县: 450821,
  册亨县: 522327,
  定州市: 130682,
  淮北市: 340600,
  潜山市: 340882,
  崇礼区: 130709,
  东乌珠穆沁旗: 152525,
  红谷滩区: 360113,
  仁布县: 540229,
  蒙自市: 532503,
  麻城市: 421181,
  保康县: 420626,
  陆良县: 530322,
  溆浦县: 431224,
  大洼区: 211104,
  罗江区: 510604,
  达孜区: 540104,
  北湖区: 431002,
  原州区: 640402,
  盈江县: 533123,
  会宁县: 620422,
  徐闻县: 440825,
  恒山区: 230303,
  建湖县: 320925,
  驻马店市: 411700,
  威县: 130533,
  赞皇县: 130129,
  金溪县: 361027,
  江宁区: 320115,
  汶川县: 513221,
  城口县: 500229,
  武义县: 330723,
  丹寨县: 522636,
  运城市: 140800,
  金沙县: 520523,
  武胜县: 511622,
  白碱滩区: 650204,
  秀英区: 460105,
  囊谦县: 632725,
  惠济区: 410108,
  蒙城县: 341622,
  彰武县: 210922,
  桦川县: 230826,
  新罗区: 350802,
  称多县: 632723,
  新都区: 510114,
  雷波县: 513437,
  清涧县: 610830,
  勉县: 610725,
  邢台市: 130500,
  衡阳县: 430421,
  广西壮族自治区: 450000,
  高安市: 360983,
  果洛藏族自治州: 632600,
  芜湖县: 340221,
  扎兰屯市: 150783,
  石柱土家族自治县: 500240,
  彝良县: 530628,
  铜官区: 340705,
  辽阳县: 211021,
  婺源县: 361130,
  荔浦市: 450381,
  沙县: 350427,
  团风县: 421121,
  娄底市: 431300,
  安多县: 540624,
  珲春市: 222404,
  汨罗市: 430681,
  武穴市: 421182,
  浉河区: 411502,
  龙门县: 441324,
  崇阳县: 421223,
  米易县: 510421,
  溪湖区: 210503,
  宁乡市: 430182,
  沿河土家族自治县: 520627,
  宁强县: 610726,
  红河县: 532529,
  濉溪县: 340621,
  西宁市: 630100,
  蚌山区: 340303,
  拜泉县: 230231,
  殷都区: 410505,
  弋江区: 340203,
  鲤城区: 350502,
  沛县: 320322,
  峡江县: 360823,
  珠海市: 440400,
  贵溪市: 360681,
  绥滨县: 230422,
  墨竹工卡县: 540127,
  肃南裕固族自治县: 620721,
  灵璧县: 341323,
  运河区: 130903,
  正蓝旗: 152530,
  黎城县: 140426,
  建始县: 422822,
  秦淮区: 320104,
  龙里县: 522730,
  凭祥市: 451481,
  扎赉特旗: 152223,
  宏伟区: 211004,
  萧山区: 330109,
  乌兰县: 632821,
  舒城县: 341523,
  石峰区: 430204,
  西峡县: 411323,
  井研县: 511124,
  小店区: 140105,
  扎鲁特旗: 150526,
  金平苗族瑶族傣族自治县: 532530,
  思明区: 350203,
  吉利区: 410306,
  江南区: 450105,
  点军区: 420504,
  襄垣县: 140423,
  澄迈县: 469023,
  索县: 540626,
  临渭区: 610502,
  观山湖区: 520115,
  竹溪县: 420324,
  罗定市: 445381,
  巴南区: 500113,
  泰顺县: 330329,
  民乐县: 620722,
  昌宁县: 530524,
  孟州市: 410883,
  晋州市: 130183,
  平谷区: 110117,
  中山区: 210202,
  瑞金市: 360781,
  桐庐县: 330122,
  双江拉祜族佤族布朗族傣族自治县: 530925,
  路南区: 130202,
  江津区: 500116,
  汉台区: 610702,
  大兴区: 110115,
  武川县: 150125,
  永仁县: 532327,
  双牌县: 431123,
  武乡县: 140429,
  伊吾县: 650522,
  大庆市: 230600,
  新沂市: 320381,
  乐东黎族自治县: 469027,
  威宁彝族回族苗族自治县: 520526,
  港闸区: 320611,
  安仁县: 431028,
  瓮安县: 522725,
  楚雄市: 532301,
  珙县: 511526,
  玉山县: 361123,
  大英县: 510923,
  白朗县: 540228,
  温江区: 510115,
  三元区: 350403,
  仁寿县: 511421,
  矿区: 140303,
  荆州区: 421003,
  洛宁县: 410328,
  天镇县: 140222,
  白山市: 220600,
  桃源县: 430725,
  濮阳市: 410900,
  云岩区: 520103,
  临安区: 330112,
  兰溪市: 330781,
  景县: 131127,
  连山壮族瑶族自治县: 441825,
  修水县: 360424,
  复兴区: 130404,
  广信区: 361104,
  西安市: 610100,
  四平市: 220300,
  兰山区: 371302,
  沧州市: 130900,
  遂川县: 360827,
  襄州区: 420607,
  靖远县: 620421,
  沙河口区: 210204,
  无锡市: 320200,
  枣庄市: 370400,
  河口瑶族自治县: 532532,
  恩施市: 422801,
  绍兴市: 330600,
  巴东县: 422823,
  旺苍县: 510821,
  衡山县: 430423,
  葫芦岛市: 211400,
  礼泉县: 610425,
  仙桃市: 429004,
  港口区: 450602,
  喀喇沁旗: 150428,
  乌翠区: 230718,
  确山县: 411725,
  新平彝族傣族自治县: 530427,
  敦煌市: 620982,
  天宁区: 320402,
  秦都区: 610402,
  琼山区: 460107,
  林口县: 231025,
  含山县: 340522,
  紫金县: 441621,
  郊区: 340711,
  甘南县: 230225,
  长宁区: 310105,
  柳北区: 450205,
  玉门市: 620981,
  益阳市: 430900,
  日照市: 371100,
  铁门关市: 659006,
  沐川县: 511129,
  桐柏县: 411330,
  大余县: 360723,
  北票市: 211381,
  北镇市: 210782,
  永州市: 431100,
  邗江区: 321003,
  松溪县: 350724,
  阳新县: 420222,
  勐腊县: 532823,
  甘德县: 632623,
  蓟州区: 120119,
  张北县: 130722,
  泉州市: 350500,
  源汇区: 411102,
  九龙坡区: 500107,
  沿滩区: 510311,
  芷江侗族自治县: 431228,
  万宁市: 469006,
  河南省: 410000,
  海南省: 460000,
  栖霞区: 320113,
  中阳县: 141129,
  沧县: 130921,
  东平县: 370923,
  静海区: 120118,
  泸定县: 513322,
  黄梅县: 421127,
  洛南县: 611021,
  清河区: 211204,
  金城江区: 451202,
  青原区: 360803,
  天元区: 430211,
  新抚区: 210402,
  苍溪县: 510824,
  尤溪县: 350426,
  施秉县: 522623,
  鸡西市: 230300,
  相山区: 340603,
  开原市: 211282,
  辰溪县: 431223,
  阳东区: 441704,
  故城县: 131126,
  长洲区: 450405,
  盐边县: 510422,
  镜湖区: 340202,
  信都区: 130503,
  盐亭县: 510723,
  利州区: 510802,
  渝北区: 500112,
  威远县: 511024,
  宿城区: 321302,
  甘谷县: 620523,
  青冈县: 231223,
  曲阜市: 370881,
  长岭县: 220722,
  平乐县: 450330,
  古县: 141025,
  巴林左旗: 150422,
  德昌县: 513424,
  淄川区: 370302,
  彭山区: 511403,
  项城市: 411681,
  沙依巴克区: 650103,
  石鼓区: 430407,
  靖西市: 451081,
  碧江区: 520602,
  威海市: 371000,
  兴义市: 522301,
  扎囊县: 540521,
  三水区: 440607,
  绥阳县: 520323,
  郾城区: 411103,
  昌江黎族自治县: 469026,
  庐阳区: 340103,
  晋江市: 350582,
  烟台市: 370600,
  德宏傣族景颇族自治州: 533100,
  雁峰区: 430406,
  灵宝市: 411282,
  惠农区: 640205,
  哈密市: 650500,
  新化县: 431322,
  岐山县: 610323,
  夏县: 140828,
  砀山县: 341321,
  镇远县: 522625,
  荣县: 510321,
  永嘉县: 330324,
  新干县: 360824,
  临洮县: 621124,
  贵德县: 632523,
  郏县: 410425,
  马尔康市: 513201,
  鹰潭市: 360600,
  柯城区: 330802,
  思茅区: 530802,
  建德市: 330182,
  平遥县: 140728,
  武安市: 130481,
  威信县: 530629,
  湟中区: 630106,
  高要区: 441204,
  龙圩区: 450406,
  桓台县: 370321,
  金台区: 610303,
  陵川县: 140524,
  六安市: 341500,
  塔城地区: 654200,
  武江区: 440203,
  老河口市: 420682,
  龙井市: 222405,
  麟游县: 610329,
  康乐县: 622922,
  泾源县: 640424,
  辽中区: 210115,
  临漳县: 130423,
  定远县: 341125,
  洮北区: 220802,
  船山区: 510903,
  新晃侗族自治县: 431227,
  乐都区: 630202,
  富平县: 610528,
  万载县: 360922,
  江陵县: 421024,
  中方县: 431221,
  贵南县: 632525,
  卧龙区: 411303,
  西昌市: 513401,
  凤冈县: 520327,
  海兴县: 130924,
  石城县: 360735,
  杂多县: 632722,
  罗源县: 350123,
  株洲市: 430200,
  肃州区: 620902,
  钦州市: 450700,
  东山区: 230406,
  奎文区: 370705,
  新北区: 320411,
  振安区: 210604,
  寿阳县: 140725,
  图们市: 222402,
  宿豫区: 321311,
  贵定县: 522723,
  菏泽市: 371700,
  布尔津县: 654321,
  景洪市: 532801,
  金凤区: 640106,
  东阿县: 371524,
  大关县: 530624,
  宁河区: 120117,
  铁岭市: 211200,
  聂拉木县: 540235,
  福贡县: 533323,
  襄汾县: 141023,
  桥西区: 130703,
  富阳区: 330111,
  白玉县: 513331,
  七星关区: 520502,
  德阳市: 510600,
  通化市: 220500,
  彭阳县: 640425,
  衡南县: 430422,
  苏家屯区: 210111,
  泽普县: 653124,
  沅江市: 430981,
  海州区: 320706,
  盘州市: 520281,
  翁牛特旗: 150426,
  保山市: 530500,
  岳阳楼区: 430602,
  天祝藏族自治县: 620623,
  腾冲市: 530581,
  巴宜区: 540402,
  仙居县: 331024,
  华亭市: 620881,
  高密市: 370785,
  洱源县: 532930,
  青铜峡市: 640381,
  喀什市: 653101,
  平昌县: 511923,
  天柱县: 522627,
  阳泉市: 140300,
  蓬莱区: 370614,
  凉山彝族自治州: 513400,
  叠彩区: 450303,
  枞阳县: 340722,
  杭州市: 330100,
  来宾市: 451300,
  木垒哈萨克自治县: 652328,
  锦州市: 210700,
  肥乡区: 130407,
  花都区: 440114,
  北安市: 231181,
  余姚市: 330281,
  丰润区: 130208,
  澄江市: 530481,
  深泽县: 130128,
  东山县: 350626,
  安居区: 510904,
  安远县: 360726,
  下陆区: 420204,
  漳平市: 350881,
  夏津县: 371427,
  湘阴县: 430624,
  眉县: 610326,
  青岛市: 370200,
  科尔沁右翼前旗: 152221,
  伊宁县: 654021,
  横峰县: 361125,
  云南省: 530000,
  延平区: 350702,
  鼓楼区: 410204,
  郴州市: 431000,
  科尔沁右翼中旗: 152222,
  黑龙江省: 230000,
  连云港市: 320700,
  福泉市: 522702,
  东台市: 320981,
  城北区: 630105,
  长顺县: 522729,
  武城县: 371428,
  望江县: 340827,
  青秀区: 450103,
  海原县: 640522,
  源城区: 441602,
  青川县: 510822,
  怀远县: 340321,
  游仙区: 510704,
  昌都市: 540300,
  东坡区: 511402,
  邻水县: 511623,
  莱西市: 370285,
  英德市: 441881,
  临翔区: 530902,
  云和县: 331125,
  岳普湖县: 653128,
  柯桥区: 330603,
  改则县: 542526,
  宣恩县: 422825,
  剑河县: 522629,
  马边彝族自治县: 511133,
  那坡县: 451026,
  凤县: 610330,
  丰台区: 110106,
  吉安县: 360821,
  沙雅县: 652924,
  大安市: 220882,
  吉林市: 220200,
  馆陶县: 130433,
  平乡县: 130532,
  金门县: 350527,
  淮南市: 340400,
  郑州市: 410100,
  云州区: 140215,
  马鞍山市: 340500,
  永修县: 360425,
  杨浦区: 310110,
  莱芜区: 370116,
  沙市区: 421002,
  历城区: 370112,
  中宁县: 640521,
  掇刀区: 420804,
  江永县: 431125,
  抚州市: 361000,
  叶县: 410422,
  恩施土家族苗族自治州: 422800,
  阿克苏地区: 652900,
  虞城县: 411425,
  浦江县: 330726,
  庆元县: 331126,
  武邑县: 131122,
  零陵区: 431102,
  赤水市: 520381,
  阿鲁科尔沁旗: 150421,
  赣州市: 360700,
  湄潭县: 520328,
  龙海市: 350681,
  墨玉县: 653222,
  剑阁县: 510823,
  花山区: 340503,
  海勃湾区: 150302,
  永吉县: 220221,
  涿鹿县: 130731,
  岳阳县: 430621,
  辉南县: 220523,
  汾西县: 141034,
  海东市: 630200,
  科尔沁区: 150502,
  新吴区: 320214,
  鄢陵县: 411024,
  瓜州县: 620922,
  香坊区: 230110,
  砚山县: 532622,
  宜兴市: 320282,
  湖南省: 430000,
  景德镇市: 360200,
  霍尔果斯市: 654004,
  铅山县: 361124,
  临朐县: 370724,
  平潭县: 350128,
  淇县: 410622,
  五莲县: 371121,
  椒江区: 331002,
  梅列区: 350402,
  子长市: 610681,
  班戈县: 540627,
  龙泉驿区: 510112,
  东安区: 231002,
  通渭县: 621121,
  城阳区: 370214,
  沂南县: 371321,
  巨野县: 371724,
  南芬区: 210505,
  晋城市: 140500,
  海安市: 320685,
  海伦市: 231283,
  会昌县: 360733,
  明山区: 210504,
  黑水县: 513228,
  汉滨区: 610902,
  沙湾县: 654223,
  滑县: 410526,
  君山区: 430611,
  綦江区: 500110,
  广平县: 130432,
  措勤县: 542527,
  平和县: 350628,
  四会市: 441284,
  播州区: 520304,
  象山区: 450304,
  文县: 621222,
  江苏省: 320000,
  增城区: 440118,
  青浦区: 310118,
  玉林市: 450900,
  澜沧拉祜族自治县: 530828,
  南京市: 320100,
  古浪县: 620622,
  庆安县: 231224,
  镇江市: 321100,
  山亭区: 370406,
  临颍县: 411122,
  富裕县: 230227,
  霍城县: 654023,
  江阴市: 320281,
  曲周县: 130435,
  旬阳县: 610928,
  泗洪县: 321324,
  衡东县: 430424,
  袁州区: 360902,
  广阳区: 131003,
  米脂县: 610827,
  新巴尔虎右旗: 150727,
  灵寿县: 130126,
  汝城县: 431026,
  沙湾区: 511111,
  长丰县: 340121,
  宜昌市: 420500,
  温岭市: 331081,
  七里河区: 620103,
  商都县: 150923,
  惠城区: 441302,
  武功县: 610431,
  爱辉区: 231102,
  安吉县: 330523,
  诏安县: 350624,
  襄城区: 420602,
  雁江区: 512002,
  洞头区: 330305,
  江岸区: 420102,
  石首市: 421081,
  迁西县: 130227,
  永城市: 411481,
  突泉县: 152224,
  老边区: 210811,
  嘉祥县: 370829,
  象山县: 330225,
  延庆区: 110119,
  天心区: 430103,
  清河县: 130534,
  沙坪坝区: 500106,
  金堂县: 510121,
  九原区: 150207,
  昌吉市: 652301,
  双流区: 510116,
  淮安市: 320800,
  新田县: 431128,
  官渡区: 530111,
  宣城市: 341800,
  盱眙县: 320830,
  华龙区: 410902,
  宜宾市: 511500,
  南山区: 440305,
  同江市: 230881,
  石嘴山市: 640200,
  宁远县: 431126,
  滁州市: 341100,
  博乐市: 652701,
  松原市: 220700,
  张店区: 370303,
  沁阳市: 410882,
  土默特右旗: 150221,
  嘉鱼县: 421221,
  鄠邑区: 610118,
  秀峰区: 450302,
  平川区: 620403,
  额敏县: 654221,
  沁水县: 140521,
  鹰手营子矿区: 130804,
  固原市: 640400,
  郓城县: 371725,
  襄城县: 411025,
  彭泽县: 360430,
  海宁市: 330481,
}
