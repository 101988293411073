import React from 'react'
import { useHistory } from 'react-router-dom'
import { ContentLayout, Toast } from 'components'

const VideoMonitor = props => {
	const [noVideo, setNoVideo] = React.useState(props.farmId === null)
	const history = useHistory()
	let flvPlayer
	var isLoop = false

	React.useEffect(() => {
		// destroyPlayer()
		if (!props.url) {
			setNoVideo(true)
			return
		}
		setTimeout(() => {
			play(props.url)
		}, 3000)
		setNoVideo(false)
	}, [props.url])

	// const getSuffix = (url) => {
	// 	const _arr = url.split('.')
	// 	return _arr[_arr.length - 1]
	// }

	const getSuffix = (url) => {
		const _arr = url.split('.')
		const _lastArr = _arr[_arr.length - 1];
		const _arrSplit = _lastArr.split('?');
		return _arrSplit[0];
	}

	const play = url => {
		const videoElement = document.getElementById('beefarm_video')
		if (videoElement === null) return
		var typeSuffix = getSuffix(url);
		if (typeSuffix != 'flv') {
			isLoop = true
		}
		flvPlayer = window.flvjs.createPlayer({
			// type: 'mp4',flv
			type: typeSuffix,
			url,
			isLive: true,
			hasAudio: false,
		})
		flvPlayer.attachMediaElement(videoElement)
		flvPlayer.on(window.flvjs.Events.ERROR, (error) => {
			console.log(error)
		})
		flvPlayer.load()
		// flvPlayer.play()
	}

	const destroyPlayer = async () => {
		if (flvPlayer) {
			flvPlayer.pause()
			flvPlayer.unload()
			flvPlayer.detachMediaElement()
			flvPlayer.destroy()
			flvPlayer = null
		}
	}

	return (
		<ContentLayout isVideo={true} title="视频监控" icon="ic_jiankong" onActionClick={() => {
			if (noVideo) {
				Toast.warning('暂无监控视频')
				return
			}
			history.push('/video')
			window.location.reload()
		}}>
			{noVideo ? (
				<div className="no-data">暂无监控视频</div>
			) : (
					<div style={{ height: '100%', width: '100%' }}>
						<video
							id="beefarm_video"
							style={{ objectFit: 'cover' }}
							width="100%"
							height="100%"
							controlsList="nodownload"
							disablePictureInPicture
							controls
							muted
							autoPlay
							loop={{isLoop}}
						></video>
					</div>
				)}
		</ContentLayout>
	)
}

export default VideoMonitor
