import React from 'react'
import RcTable from 'rc-table'
import Loading from '@jiaminghi/data-view-react/es/loading'
import Pagination from '../pagination'
import 'rc-table/assets/index.css'
import './index.less'

const ITable = props => {
  const rowClassName = (record, index, indent) => {
    if (index % 2 === 0) {
      return 'table-row-odd'
    }
    return 'table-row-even'
  }

  return (
    props.show ? <div className="table-wrapper">
      {props.loading && <Loading style={{ position: 'absolute', zIndex: 999 }} />}
      <RcTable
        {...props}
        className="i-table"
        rowClassName={rowClassName}
        style={{ marginTop: 2 }}
        emptyText="暂无数据"
      />
      {props.pagination && <Pagination {...props.pagination} onChange={props.onChange} />}
      {props.extra && (
        <div className="footer">
          <span className="tip">只显示前100名蜂场，地理区域进入县级，可查看县级所有蜂场</span>
        </div>
      )}
    </div> : <></>
  )
}

export default ITable
