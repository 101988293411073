import React, { useEffect, useState } from 'react'
import { ContentLayout, PuffLoading } from 'components'
import './index.less'

const BeekeepGovernance = (props) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!props.data) return
    const myChart = window.echarts.init(document.getElementById('governance-chart'))
    const d = [
      { name: '下痢病治理', value: props.data.chalk, color: '#cccc9a' },
      { name: '巢虫治理', value: props.data.beeMite, color: '#62c491' },
      { name: '囊状幼虫治理', value: props.data.cysticLarva, color: '#ed727c' },
      { name: '孢子虫治理', value: props.data.spore, color: '#7368ee' },
      { name: '欧洲幼虫治理', value: props.data.larvaeRot, color: '#53b5df' },
    ]
    setData(d)
    myChart.setOption(getOpt(d))
  }, [props.data])

  const getOpt = (data) => {
    return {
      color: ['#cccc9a', '#62c491', '#ed727c', '#7368ee', '#53b5df'],
      xAxis: {
        type: 'category',
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#2590B0',
          },
        },
        axisLabel: {
          color: '#2590B0',
        },
      },
      grid: {
        top: '1%',
        height: '65%',
      },
      yAxis: {
        type: 'value',
        nameTextStyle: {
          color: '#2590B0',
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: '#2590B0',
          },
        },
        axisLabel: {
          color: '#2590B0',
        },
      },
      series: data.map((item, index) => {
        return {
          type: "bar",
          name: item.name,
          data: [{
            name: item.name,
            value: item.value
          }]
        }
      })
    }
  }

  return (
  <ContentLayout title="养蜂治理" icon="ic_laiyuan">
    {props.loading ? <div className="content-layout-loading">
      <PuffLoading></PuffLoading>
    </div> : <div className="governance-wrapper">
        <div id='governance-chart' style={{ height: '100%' }}></div>
        <div className="chart-legends">
          {data?.map(d => <div key={d.name} className="legend-item">
            <div className="legend-name">
              <i className="symbol" style={{ backgroundColor: d.color }}></i>
              <span className="name" style={{ color: d.color }}>{d.name}</span>
            </div>
            <div className="legend-value">{d.value}</div>
          </div>)}
        </div>
      </div>}
  </ContentLayout>)
}

export default BeekeepGovernance