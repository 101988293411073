import React, { useEffect, useState } from 'react'
import { ContentLayout, ContentModal, PieChart } from 'components'
import './index.less'

const IllBee = props => {
  // const name_arrs = ['中蜂群', '中蜂蜜', "", '意蜂群', '意蜂蜜'].reverse()
  // const color = ['#8761E9', "#EB7B7B", '', "#E7C953", "#FFE789"].reverse()
  const name_arrs = ['中蜂群', '蜜产量', '销售额'].reverse()
  const color = ['#8761E9', "#EB7B7B", "#D5BB60"].reverse()
  const [modalVisible, setModalVisible] = useState(false)
  const [chartData, setChartData] = useState(null)

  useEffect(() => {
    if (props.data) {
      const myChart = window.echarts.init(document.getElementById('ill-bee-cc'))
      const _d = [
        { name: '中蜂/中蜂蜜', data: [props.data.beeColonies, props.data.beeHoneyProduction, props.data.beeHoneySales], color: '#ed727a' },
        // { name: '意蜂/意蜂蜜', data: [props.data.italianColonies, props.data.italianHoneyProd, props.data.italianHoneySales], color: '#53b4df' },
      ]
      setChartData(_d)

      myChart.setOption(getOpt(props.data))
    }
  }, [props.data])

  const getOpt = (obj) => {
    const data = [
      // {
      //   name: "意蜂群",
      //   value: obj.italianColonies,
      //   label: `${obj.italianColonies}群`,
      // },
      // {
      //   name: "意蜂蜜",
      //   value: obj.italianHoneySales,
      //   label: `${obj.italianHoneyProd}吨 | ￥${obj.italianHoneySales}万`,
      // },
      // { value: 0, weight: 0, count: 0, price: 0 },
      // {
      //   name: "中锋群",
      //   value: obj.beeColonies,
      //   label: `${obj.beeColonies}群`,
      // },
      // {
      //   name: "中锋蜜",
      //   label: `${obj.beeHoneyProduction}吨| ￥${obj.beeHoneySales}万`,
      //   value: obj.beeHoneySales
      // },

      // { value: 0, weight: 0, count: 0, price: 0 },

      {
        name: "销售额",
        label: `￥${obj.beeHoneySales}万`,
        value: obj.beeHoneySales * 10
      },
      {
        name: "蜜产量",
        label: `${obj.beeHoneyProduction}吨`,
        value: obj.beeHoneyProduction * 30
      },
      {
        name: "中锋群",
        label: `${obj.beeColonies}群`,
        value: obj.beeColonies
      },
      
      
    ]

    return {
      grid: {
        top: '0%',
        left: '4%',
        right: '4%',
        bottom: '0%',
        //图形位置包含坐标轴的刻度标签
        //如果图形位置调整好却不包含坐标轴，坐标轴信息会显示不全
        containLabel: true
      },
      xAxis: {
        show: false,
        axisLine: {
          show: false
        },
        splitLine: {
          show: false
        },
        type: 'value',
        //避免文字溢出消失，所以在最大的基础上额外多加一些
        max: data.slice().sort((item1, item2) => {
          return item2.value - item1.value
        })[0].value + 5000,
        axisLabel: {
          show: false
        },
      },
      yAxis: {
        type: 'category',
        data: name_arrs,
        //y轴线样式
        axisLine: {
          show: false
        },
        //设置与x轴平行的线(分割线)不显示
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          formatter: function (value, index) {
            return name_arrs[index]
          },
          margin: 16,
          height: 40,
          color: "#17B7E3",
          fontSize: 16
        }
      },
      series: [{
        name: "",
        type: 'bar',
        barWidth: '30',
        data: data.map((item, index) => {
          if (!name_arrs[index]) return {}
          return {
            name: name_arrs[index],
            value: item.value,
            itemStyle: {
              normal: {
                show: true,
                color: color[index],
              }
            },
            barBorderWidth: 20,
            label: {
              color: "#17B7E3"
            },
            //将data的数据展开用于下面label文字设置
            params: {
              ...item
            }
          }
        }),
        label: {
          normal: {
            formatter: function (obj) {

              let {
                params
              } = obj.data
              return params.label
            },
            show: true,
            position: 'right',
            distance: 5,
            padding: 0
          }
        },
      }]
    }
  }

  return (
    <ContentLayout title="蜂种及产销统计" icon="ic_chonghai" onActionClick={() => {
      setModalVisible(true)
    }}>
      <div id='ill-bee-cc' style={{ height: '100%' }}></div>
      <ContentModal
        className="line-chart-modal"
        visible={modalVisible}
        title="蜂种及产销统计0"
        onCancel={_ => setModalVisible(false)}
      >
        <PieChart
          titles={[
            { title: '蜂群分布', unit: '群' },
            { title: '蜂蜜产量统计', unit: '吨' },
            { title: '蜂蜜销售统计', unit: '万元' }
          ]}
          data={chartData}
        />
      </ContentModal>
    </ContentLayout>
  )
}

export default IllBee
