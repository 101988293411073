import React from 'react'
import './index.less'

const TriangleBorderWrapper = props => {
	return (
		<div
			{...props}
			className={`${props.className} triangle-border-wrapper${props.ghost ? ' ghost' : ''} ${props.login ? ' login' : ''} ${
				props.small ? ' small' : ''
				} `}
		>
			<div className="content">{props.children}</div>
		</div>
	)
}

export default TriangleBorderWrapper
