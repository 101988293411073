import React, { useEffect } from 'react'
import './pie_chart.less'

const PieChart = props => {
  useEffect(() => {
    const myChart = window.echarts.init(document.getElementById('pie'))

    myChart.setOption(getBaseOption())
  }, [])

  const seriesProperties = ({
    name: '',
    type: 'pie',
    radius: ['15%', '45%'],
    itemStyle: { borderColor: '#223e52', borderWidth: 3 },
    labelLine: { show: false },
    label: { position: 'inner', formatter: '{c}' }
  })

  const getBaseOption = () => {
    const data = props.data

    return {
      top: '10%',
      textStyle: { fontSize: 18, },
      title: { text: '', subtext: '', left: 'center', top: '20' },
      tooltip: { trigger: 'item', formatter: '{b}:{c}' },
      legend: { top: '5%', textStyle: { color: '#17b7e3', fontWeight: 'bold' } },
      series: data[0].data.map((d, i) => ({
        ...seriesProperties,
        center: [`${props.titles.length === 3 ? (20 + i * 30) : (27 + 44 * i)}%`, '60%'],
        data: data?.map(d => ({ name: d.name, value: d.data[i], itemStyle: { color: d.color } }))
      }))
    }
  }

  return <div className='pie-chart-container'>
    <div id='pie' style={{ height: '100%' }}>1</div>
    {<div className="pie-title-wrapper" style={{ paddingLeft: 30, paddingRight: 48 }}>
      {props.titles.map((t, k) =>
        <div key={k} className="pie-title-item">
          <span className="title">· {t.title} ·</span>
          <span className="unit">(单位：{t.unit})</span>
        </div>
      )}
    </div>}
  </div>
}

export default PieChart
