import React from 'react'
import ScrollBoard from '@jiaminghi/data-view-react/es/scrollBoard'
import icon_dian from 'assets/beefarm/icon_dian.png'

const IScrollBoard = props => {
	const firstC = `<img src='${icon_dian}' alt=''/>`

	return (
		<div style={{ flex: 1, ...props.style }}>
			<ScrollBoard
				config={{
					headerBGC: '#1B3045',
					oddRowBGC: '#19212f',
					evenRowBGC: '#19212f',
					...props,
					waitTime: props.waitTime ?? 4000,
					data: props.showDian ? props.data.map(i => [firstC, ...i]) : props.data,
				}}
				// onMouseOver={p => {
				// 	// console.log(p)
				// 	// Element a = document.getElementsByClassName('dv-scroll-board')
				// 	// a.getElementsByClassName('row-item')
				// 	// $('.dv-scroll-board .row-item').on('mouseover', e => {
				// 	// 	e.target.parentNode.classList.add('row-hover')
				// 	// })
				// 	// $('.dv-scroll-board .row-item').on('mouseout', e => {
				// 	// 	e.target.parentNode.classList.remove('row-hover')
				// 	// })
				// }}
				onClick={p => {
					if (props.onClick) props.onClick(p)
				}}
				style={{
					width: '100%',
					height: '100%',
					fontWeight: 'bold',
					color: '#2590B0',
					cursor: 'pointer',
				}}
			/>
		</div>
	)
}

export default IScrollBoard
