import React, { useState, useEffect } from 'react'
import $$ from 'cmn-utils'
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.less'
import 'swiper/components/pagination/pagination.less'
import './index.less'

SwiperCore.use([Pagination, Autoplay])

const LeaderStyle = (props) => {
  const [imgs, setImgs] = useState([])

  useEffect(() => {
    $$.post('/data/technical/supports/leadership', {}).then(res => {
      if (res.code !== 1) return
      setImgs(res.data.slice(0, 10))
    })
  }, [])

  return (<div className='carousel-wrapper'>
    <Swiper
      autoplay
      loop={true}
      initialSlide={1}
      pagination={{
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>'
        },
      }}
    >
      {imgs.map((i, j) =>
        <SwiperSlide key={j}>
          <img className='swiper-slide-img' src={i.picture} alt="" />
          <div className="footer">
            <span className='title'>{i.title}</span>
            {/* <span className="time">{i.crtTime?.split(' ')[0]}</span> */}
          </div>
        </SwiperSlide>
      )}
    </Swiper>
  </div>)
}

export default LeaderStyle