import React, { useEffect, useState } from 'react'
import $$ from 'cmn-utils'
import { ContentLayout, PuffLoading } from 'components'

import './index.less'

const WeatherWidget = ({ isToday, label, temperature, weather, humidity, rain }) => {
  return (
    <div className={`weather-item${isToday ? ' today' : ''}`}>
      <div className={`weather-item-row${isToday ? ' today' : ''}`}>
        <span className="label">{label}</span>
        <span className="value">{temperature}</span>
      </div>
      <div className={`weather-item-row weather${isToday ? ' today' : ''}`}>
        <span>{weather}</span>
      </div>
      <div className="weather-item-row" style={{ marginTop: 20 }}>
        <span className="label">湿度</span>
        <span className="value">{humidity}</span>
      </div>
      <div className="weather-item-row">
        <span className="label">降水</span>
        <span className="value">{rain}</span>
      </div>
    </div>
  )
}

const WeatherInfo = props => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)

  useEffect(() => {
    $$.post('/data/nectar/weather', {}).then((res) => {
      setLoading(false)
      if (res.code !== 1 || !res.data || !res.data.data) return
      const d = res.data.data, _arr = []
      // console.log(res.data)
      for (let i = 0; i < 3; i++) {
        const element = d[i]

        _arr.push({
          isToday: i === 0,
          // label: i === 0 ? '竹山县aa' + i : (i === 1 ? '明日' : '后天'),
          label: i === 0 && res.data.city != null ? res.data.city : (i === 1 ? '明日' : '后天'),
          temperature: element.tem,
          weather: element.wea,
          humidity: (element.humidity === '' || !element.humidity) ? '0%' : element.humidity,
          rain: '无'
        })
      }
      setData(_arr)
    })
  }, [])

  return (<ContentLayout title='天气气象统计' icon="ic_qixiang">
    {
      loading ?
        <div className="content-layout-loading">
          <PuffLoading></PuffLoading>
        </div>
        :
        <div className="weather-info">
          {data?.map((d, i) => <WeatherWidget key={i} {...d}></WeatherWidget>)}
        </div>
    }
  </ContentLayout>)
}

export default WeatherInfo