import React, { useState, useEffect } from 'react'
import $$ from 'cmn-utils'
import { ContentModal, Table } from 'components'

const defaultPageSize = 10
const TableModal = (props) => {
  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [total, setTotal] = useState(0)
  const getColumns = () => {
    return [
      { align: 'center', title: '序号', dataIndex: 'no', width: 80, render: (t, r, i) => (i + 1) + ((pageNumber - 1) * 10) },
      { align: 'center', title: '蜂场名称', dataIndex: 'name', width: 200, ellipsis: true, className: 'table-title' },
      { align: 'center', title: '蜂箱数', dataIndex: 'hivesNumber', width: 100, ellipsis: true, className: 'table-title' },
      { align: 'center', title: '管理人', dataIndex: 'managerName', width: 100, ellipsis: true, className: 'table-title' },
      { align: 'center', title: '联系电话', dataIndex: 'phone', width: 120, ellipsis: true, className: 'table-title' },
      { align: 'center', title: '详细地址', dataIndex: 'address', width: 400, ellipsis: true, className: 'table-title' },
    ]
  }

  useEffect(() => {
    $$.on('showFarmList', _ => {
      setVisible(true)
      queryList(1)
    })
    queryList(1)
  }, [])

  const queryList = (pageNumber = 1, pageSize = defaultPageSize) => {
    $$.post('/beeFarm/data/list', { pageNumber, pageSize, areaCode: $$.getStore('currentAreaCode') === 100000 ? undefined : $$.getStore('currentAreaCode') }).then((res) => {
      if (res.code !== 1) return
      setPageNumber(pageNumber)
      setDataSource(res.data.records)
      setTotal(res.data.total)
    })
  }

  return (
    <ContentModal
      title='蜂场列表'
      height='70%'
      visible={visible}
      className='news-modal'
      onCancel={_ => {
        setVisible(false)
      }}
    >
      <Table
        show={true}
        rowKey='id'
        columns={getColumns()}
        data={dataSource}
        scroll={{ y: 600, }}
        onChange={queryList}
        pagination={{ total, pageSize: defaultPageSize }}
      />
    </ContentModal>
  )
}

export default TableModal