import React, { useEffect } from 'react'
import $$ from 'cmn-utils'
import { useHistory } from 'react-router-dom'
// import VideoWidget from './video_widget'
import VideoNewWidget from './video_new_widget'
import VideoMonitorComponents from 'components/video_monitor_components'
import './index.less'

const VideoListNewPage = props => {
  // const [loading, setLoading] = React.useState(true)
  const [dataSource, setDataSource] = React.useState([])
  const [videos, setVideos] = React.useState([])
  const [pageNumber, setPageNumber] = React.useState(1)
  const history = useHistory()
  // console.log(props)
  // console.log(this.props)
  useEffect(() => {
    $$.post('/farm/monitoring/list', { pageNumber: 1, pageSize: 50 }).then(res => {
      // setLoading(false)
      if (res.code !== 1 || !res.data.records) return
      setDataSource(res.data.records)
      setVideos(res.data.records.slice((pageNumber - 1) * 4, pageNumber * 4))
    })
  }, [])

  const handlePageChange = (pageNumber) => {
    setVideos(dataSource.slice((pageNumber - 1) * 4, pageNumber * 4))
    setPageNumber(pageNumber)
    // $$.setStore('pageNumber', pageNumber)
    // window.location.reload()
  }

  const renderPagination = () => {
    const pages = []
    for (let i = 0; i < Math.ceil(dataSource.length / 4); i++) {
      pages.push(
        <div
          className={`page-btn-wrapper${pageNumber === i + 1 ? ' active' : ''}`}
          key={i + 1}
          onClick={() => handlePageChange(i + 1)}
        >
          {`第${i + 1}页`}
        </div>
      )
    }
    return pages
  }

  return (
    <div className="video-list-page">
      <div className="video-list">
        {/* {videos.map((v, i) => <VideoWidget key={i}  {...v} />)} */}
        {videos.map((v, i) => <VideoNewWidget key={i}  {...v} />)}

        {/* <VideoMonitorNew url={dataSource && dataSource?.mvVideo && dataSource?.mvVideo.length > 6 ? dataSource?.mvVideo : ""} vid={0}  isShow={true}></VideoMonitorNew> */}
      </div>
      <div className="nav-bar-wrapper">
        <div className="nav-bar">
          {/* <i className="icon-video"></i> */}
          {/* <b className="page-title">视频监控</b> */}
          {/* <span className="date mt-40">{currentTime.split(' ')[0]}</span> */}
          {/* <span className="time">{currentTime.split(' ')[1].split(":").slice(0, 2).join(':')}</span> */}
          <span className="total">共{dataSource.length}个监控</span>
          <div className="pagination-wrapper">
            {renderPagination()}
          </div>
          <div className="back">
            <div className="back-btn" onClick={() => history.goBack()}>返回</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoListNewPage