import React, { useState } from 'react'
import $$ from 'cmn-utils'
import { useWillMount, ContentModal, TriangleBorderWrapper, Toast } from 'components'
import { useHistory } from 'react-router-dom'
import Form, { Field } from 'rc-field-form'
import icon_fc0 from 'assets/beefarm/icon_fc0.png'
import icon_fc1 from 'assets/beefarm/icon_fc1.png'
import icon_miy0 from 'assets/beefarm/icon_miy0.png'
import icon_miy1 from 'assets/beefarm/icon_miy1.png'
import icon_qiuz0 from 'assets/beefarm/icon_qiuz0.png'
import icon_qiuz1 from 'assets/beefarm/icon_qiuz1.png'
import icon_user from 'assets/beefarm/iocn_face.png'
import icon_time from 'assets/beefarm/icon_time.png'
import icon_server from 'assets/beefarm/icon_server.png'
import bg_timeline from 'assets/beefarm/bg_timeline.png'

import './header.less'
import EncryptMsg from 'components/encrypt'

const titleList = [
	{ name: '蜂场数据', icon: icon_fc0, selectedIcon: icon_fc1, route: '/beefarm' },
	{ name: '蜜源统计', icon: icon_miy0, selectedIcon: icon_miy1, route: '/nectar' },
	{ name: '技术指导', icon: icon_qiuz0, selectedIcon: icon_qiuz1, route: '/askhelp' },
]
const Header = props => {
	const [nowTitleIndex, setNowTitleIndex] = useState(0)
	const history = useHistory()
	const [form] = Form.useForm()
	const [visible, setVisible] = useState(false)

	useWillMount(() => {
		const path = history.location.pathname
		const titleIndex = titleList.findIndex(t => t.route === path)
		setNowTitleIndex(path === '/' || path === '/video' ? 0 : titleIndex)
	})

	/**
	 * 标题点击事件
	 * @param {int} _nowTitleIndex
	 */
	const handleTitleClick = _nowTitleIndex => {
		if (nowTitleIndex !== _nowTitleIndex) {
			setNowTitleIndex(_nowTitleIndex)
			history.replace(titleList[_nowTitleIndex].route)
			window.location.reload()
		}
	}

	/**
	 * 退出登录
	 * @param {Event} e
	 */
	const handleLogoutClick = e => {
		$$.removeStore('username')
		$$.removeStore('token')
		$$.removeStore('accountArea')
		$$.removeStore('currentAreaName')
		$$.removeStore('currentArea')
		$$.removeStore('master')
		$$.removeStore('currentBaseInfo')
		$$.removeStore('randomFarmId')
		history.push('/login')
	}

	const isEmpty = str => !str || str === ''

	const handleModifyPassword = _ => {
		form.validateFields().then(values => {
			if (isEmpty(values.oldPassword) || isEmpty(values.newPassword2) || isEmpty(values.newPassword2)) {
				Toast.warning('请填写完整表单')
				return
			}
			if (values.newPassword !== values.newPassword2) {
				Toast.warning('两次输入的密码不一致')
				return
			}

			values.oldPassword = EncryptMsg(values.oldPassword)
			values.newPassword = EncryptMsg(values.newPassword)
			values.newPassword2 = EncryptMsg(values.newPassword2)
			
			$$.post('/digita/user/password/edit', values).then(res => {
				if (res.code !== 1) {
					Toast.error(res.msg)
					return
				}
				Toast.success(res.msg)
				form.resetFields()
				setVisible(false)
				setTimeout(() => {
					handleLogoutClick()
				}, 500)
			})
		})
	}

	// 会议按钮跳转 打开exe
	function openClient() {
		window.open('openClient://')
	}

	const currentBaseInfo = $$.getStore('currentBaseInfo')
	const showElem = currentBaseInfo && currentBaseInfo.logo ? true : false

	const isShowTime = history.location.pathname == '/askhelp' && currentBaseInfo && currentBaseInfo.phone != '' ? false : true
	const showTimeDesc = () => {
		if (isShowTime) {
			return <div className="time-text">
				<img src={icon_time} alt="" />
				数据统计截止时间：{timestampToTime(new Date().getTime())}
				</div>
		} else {
			return <div className="time-text">
				<img src={icon_server} alt="" />
				服务电话：{currentBaseInfo.phone}
			</div>
		}
	}

	let meeting;
	if (currentBaseInfo && currentBaseInfo.crtBy == 20) {
		meeting = (
			<div className="center-button">
				{/* <a className="meeting" onClick={openClient}>会议系统</a> */}
				<span className="meeting" onClick={openClient}></span>
			</div>
		);
	}

	return (
		<div className="ds-header">
			<div className="left-wrapper">
				<img style={{display: showElem ? 'block' : 'none'}} src={currentBaseInfo ? currentBaseInfo.logo : ''} alt="" width={60} height={60} />
				<div className="name">
					<span style={{ fontSize: 32, fontWeight: 'bold' }}>{currentBaseInfo ? currentBaseInfo.title : ''}</span>
					<span style={{ fontSize: 13 }}>{currentBaseInfo ? currentBaseInfo.subtitle : ''}</span>
				</div>

			</div>
			<div className="middle-wrapper">
				{titleList.map((t, i) => {
					return (
						<div
							key={i}
							className={`title-item ${i === nowTitleIndex ? 'active' : ''}`}
							onClick={_ => handleTitleClick(i)}
						>
							<img src={i === nowTitleIndex ? t.selectedIcon : t.icon} alt="" />
							<span>{t.name}</span>
						</div>
					)
				})}
				<div className="time">
					<img src={bg_timeline} alt="" className="bg-timeline" />


					{showTimeDesc()}
					
					{/* <div className="time-text"> */}
						{/* <img src={icon_time} alt="" /> */}
						{/* 数据统计截止时间：{currentBaseInfo && currentBaseInfo.phone != "" ? currentBaseInfo.phone : timestampToTime(new Date().getTime()) : props.telephone} */}
						{/* 数据统计截止时间：{timestampToTime(new Date().getTime())} */}

						{/* { 
							history.location.pathname == '' && currentBaseInfo && currentBaseInfo.phone != '' ?
							(

							) : (

							)
						} */}
						{/* {history.location.pathname == '' && currentBaseInfo && currentBaseInfo.phone != '' ? } */}
			{/* 数据统计截止时间：{history.location.pathname == '' && $$.getStore() != ''}{props.phone != 0 ? props.phone : timestampToTime(new Date().getTime())} */}
					

					{/* </div> */}


				</div>
			</div>
			<div className="right-wrapper">
				<div className="area-info">
					<i className="icon-location"></i>
					<span className="label">当前区域：</span>
					<span className="now-area" id="header-now-area">{$$.getStore('currentArea').areaName}</span>
				</div>

				
				{/* 会议按钮 */}
				{meeting}


				<div className="username">
					<img className='mr-12' src={icon_user} alt="" />
					<span>{$$.getStore('username')}</span>
					<div className="options">
						<ul>
							<li onClick={_ => setVisible(true)}>修改密码</li>
							{/* {$$.getStore('master') === true && <li>子账号</li>} */}
							{$$.getStore('master') === true && <li onClick={(e) => window.open(`http://bigtfadmin.beeaiot.com/?t=${$$.getStore('token')}`)}>内容管理</li>}
							<li onClick={_ => handleLogoutClick()}>退出登录</li>
						</ul>
					</div>
				</div>
				{/* <div className="qa-news">
					<div className="qa-news-content">
						<span className="qa">专家问答</span>
						<span className="news" onClick={_ => $$.trigger('showNewsTable')}>发布新闻</span>
						<span className="message">发布消息</span>
					</div>
				</div>
				<img src={icon_exit} alt="" className="exit-btn" onClick={handleLogoutClick} /> */}
			</div>
			<ContentModal
				title='修改密码'
				visible={visible}
				className='password-modal'
				maskClosable={false}
				width={600}
				height={400}
				onCancel={_ => {
					form.resetFields()
					setVisible(false)
				}}
			>
				<Form className='password-form' form={form}>
					<div className="form-row">
						<span className="form-row-label">输入旧密码：</span>
						<div className="form-row-wrapper">
							<Field name='oldPassword'>
								<input className="form-row-wrapper-input" type="password" placeholder='此处输入旧密码' />
							</Field>
						</div>
					</div>
					<div className="form-row">
						<span className="form-row-label">输入新密码：</span>
						<div className="form-row-wrapper">
							<Field name='newPassword'>
								<input className="form-row-wrapper-input" type="password" placeholder='此处输入新密码' />
							</Field>
						</div>
					</div>
					<div className="form-row">
						<span className="form-row-label">重复新密码：</span>
						<div className="form-row-wrapper">
							<Field name='newPassword2'>
								<input className="form-row-wrapper-input" type="password" placeholder='此处重复输入新密码' />
							</Field>
						</div>
					</div>
				</Form>
				<div className="hr"></div>
				<div className='mt-24 text-center'>
					<TriangleBorderWrapper
						ghost={true}
						style={{ margin: '0 12px 0 36px' }}
						onClick={_ => {
							form.resetFields()
							setVisible(false)
						}}>
						取消
          </TriangleBorderWrapper>
					<TriangleBorderWrapper
						style={{ margin: '0 36px 0 36px' }}
						onClick={_ => handleModifyPassword()}>
						保存
          </TriangleBorderWrapper>
				</div>
			</ContentModal>
		</div>
	)
}

function timestampToTime(timestamp) {
	var date = new Date(timestamp)
	var Y = date.getFullYear() + '-'
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + ' '
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() + ':'
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	var ss = date.getSeconds() < 10 ? '0' + date.getDate() : date.getSeconds()
	return Y + M + D + hh + mm
}

export default Header
