/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import $$ from 'cmn-utils'
import { ContentModal, Table } from '@/components/index'

const defaultPageSize = 10
const NewsTableList = props => {
  const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [pageNumber, setPageNumber] = useState(1)

  const getColumns = () => {
    return [
      { align: 'center', title: '序号', dataIndex: 'no', width: 80, render: (t, r, i) => (i + 1) + ((pageNumber - 1) * 10) },
      {
        title: '标题', dataIndex: 'title', width: 600, ellipsis: true, className: 'table-title',
        onCell: (r, index) => ({
          onClick(e) {
            props.onRowClick(r.id)
          },
        }),
      },
      { align: 'center', title: '发布人', dataIndex: 'crtByName', width: 200, },
      { align: 'center', title: '发布时间', dataIndex: 'crtAt', width: 200, },
    ]
  }

  useEffect(() => {
    $$.on(props.eventName, _ => {
      setVisible(true)
      queryList()
    })
  }, [])

  const queryList = (pageNumber = 1, pageSize = defaultPageSize) => {
    $$.post('/news/list', { pageNumber, pageSize, type: props.type }).then((res) => {
      if (res.code !== 1) return
      setPageNumber(pageNumber)
      setDataSource(res.data.records)
      setTotal(res.data.total)
    })
  }

  return (
    <>
      {visible && <ContentModal
        title={props.title}
        height='70%'
        visible={visible}
        className='news-modal'
        onCancel={_ => {
          setVisible(false)
        }}
      >
        <Table
          show={true}
          rowKey='id'
          columns={getColumns()}
          data={dataSource}
          scroll={{ y: 600, }}
          onChange={queryList}
          pagination={{ total, pageSize: defaultPageSize }}
        />
      </ContentModal>}
    </>
  )
}

export default NewsTableList 