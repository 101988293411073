import React from 'react'
import { ContentLayout, Toast } from 'components'
import VideoMonitorComponents from 'components/video_monitor_components'
import { useHistory } from 'react-router-dom'

const VideoMonitorNew = props => {
	const [noVideo, setNoVideo] = React.useState(props.farmId === null)
	const history = useHistory()
	React.useEffect(() => {
		// destroyPlayer()
		if (!props.url) {
			setNoVideo(true)
			return
		}
		setNoVideo(false)
	}, [props.url])

	// const getSuffix = (url) => {
	// 	const _arr = url.split('.')
	// 	return _arr[_arr.length - 1]
	// }

	const getSuffix = (url) => {
		const _arr = url.split('.')

		const _lastArr = _arr[_arr.length - 1];
		const _arrSplit = _lastArr.split('?');
		return _arrSplit[_arrSplit.length - 1];
	}

	return (
		<ContentLayout isVideo={true} title="视频监控" icon="ic_jiankong" isShow={props.isShow} onActionClick={() => {
			if (noVideo) {
				Toast.warning('暂无监控视频')
				return
			}
			history.push('/video')
			window.location.reload()
		}} changingClick={(e) => {
			Toast.warning('bbb' + e)
		}}>
			{noVideo ? (
				<div className="no-data">暂无监控视频</div>
			) : (
					<div style={{ height: '100%', width: '100%'}}>
						<VideoMonitorComponents url={props.url} vid={props.vid}></VideoMonitorComponents>
					</div>
				)}
		</ContentLayout>
	)
}

export default VideoMonitorNew
