import React, {useEffect} from 'react'

const MultiLineChart = props => {
	const getBaseOption = () => {
		const _legend = props.dataSource.y.map(d => d.name)
		const circleSize = 6

		return {
			color: ['#FF6262', '#FFE04D', '#52FF7F', '#B842F5', '#42F5D5'],
			tooltip: {
				trigger: 'axis',
				backgroundColor: 'rgba(50,50,50,0.1)',
				borderColor: '#2590B0',
				borderWidth: 1,
				textStyle: {
					color: '#fff',
					fontWeight: 'bold',
				},
			},
			legend: {
				data: _legend.map(l => ({name: l, icon: 'circle', textStyle: {color: '#2590B0'}})),
			},
			grid: {
				left: '3%',
				right: '5%',
				bottom: '3%',
				containLabel: true,
			},
			toolbox: {
				show: false,
			},
			xAxis: {
				type: 'category',
				boundaryGap: false,
				data: props.dataSource.x,
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				axisLine: {
					lineStyle: {
						color: '#2590B0',
					},
				},
				axisLabel: {
					color: '#2590B0',
				},
			},
			yAxis: {
				name: props.dataSource.unit,
				nameTextStyle: {
					color: '#2590B0',
				},
				type: 'value',
				axisTick: {
					show: false,
				},
				splitLine: {
					show: false,
				},
				axisLine: {
					lineStyle: {
						color: '#2590B0',
					},
				},
				axisLabel: {
					// fontSize: 12,
					color: '#2590B0',
				},
			},
			series: props.dataSource.y.map(d => ({
				...d,
				type: 'line',
				stack: '总量',
				symbol: 'circle',
				symbolSize: circleSize,
			})),
		}
	}

	useEffect(() => {
		if (!props.dataSource) return

		const myChart = window.echarts.init(document.getElementById('multi-line'))
		myChart.setOption(getBaseOption())
	}, [props.dataSource])

	return <div id="multi-line" style={{height: '80%'}}></div>
}

export default MultiLineChart
