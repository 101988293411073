import React from 'react'
import "video.js/dist/video-js.css";
// import "videojs-flash"
import videojs from "video.js";

class VideoMonitorComponents extends React.Component {

    componentDidMount() {
      const {url, vid} = this.props
      
      if (url) {
        const videoJsOptions = {
          autoplay: true,
          controls: true,
          bigPlayButton: true,
          errorDisplay: true,
          sources: [
            {
              src: url,
              // type: "rtmp/flv",
              type: "application/x-mpegURL",
            },
          ],
        };

        // beefarm_new_video
        this.player = videojs("video-widget-" + vid, videoJsOptions, function onPlayerReady() {
          //(id或者refs获取节点，options，回调函数)
          // videojs.log("Your player is ready!");
          // In this context, `this` is the player that was created by Video.js.
          this.play();
          // How about an event listener?
          // this.on("ended", function () {
          //   videojs.log("Awww...over so soon?!");
          // });
        });

        if (this.player) {
          this.player.play();
        }
        
      }
    }

    componentWillUnmount() {
      if (this.player) {
        this.player.dispose();
      }
    }

    render() {
        return (

          <div style={{ height: '100%', width: '100%'}}>  
            <video muted="muted"
              style={{ height: '100%', width: '100%' }}
              // id={"beefarm_new_video"+ this.props.vid}
              // id={"video-widget-"+this.state.videoId}
              id={"video-widget-"+this.props.vid}
              className="video-js vjs-big-play-centered"
            ></video>
          </div>
        );
    }

}

export default VideoMonitorComponents;