import React from 'react'

const useWillMount = func => {
  const willMount = React.useRef(true)

  if (willMount.current) {
    func()
  }

  willMount.current = false
}

export default useWillMount
