import React, { useEffect, useState } from 'react'
import FullScreenContainer from '@jiaminghi/data-view-react/es/fullScreenContainer'
import $$ from 'cmn-utils'
import { Switch, Route } from 'react-router-dom'
import NectarPage from './nectar_page'
import AskHelpPage from './ask_help_page'
import VideoListPage from './video_list_page';
import VideoListNewPage from './video_list_new_page';
import BeefarmPage from './beefarm_page'
import Header from './header'

import './index.less'

// let mapStack = []
const IndexScreen = props => {
	// const [nowArea, setNowArea] = React.useState(mapStack[0])
	// const [farmId, setFarmId] = React.useState(null)
	// useWillMount(() => {
	// 	const currentArea = $$.getStore('currentArea')
	// 	$$.setStore('currentAreaName', currentArea.areaName)
	// 	$$.setStore('currentAreaCode', currentArea.areaCode)
	// 	$$.setStore('currentAreaLevel', currentArea.layer)
	// 	mapStack = [currentArea.areaName]
	// })
	
	// useEffect(() => {
	// 	///获取蜜种列表
	// 	$$.post('/data/nectar/species').then(res => {
	// 		if (res.code !== 1) return
	// 		$$.setStore('beeTypes', res.data.map(d => ({ key: d.id, label: d.nectarName })))
	// 	})
	// }, [])
	
	
	return (	
		<FullScreenContainer className="full-screen">
			<div className="main">
				<header className="main-header">
					<Header />
				</header>
				<Switch>
					<Route
						path="/beefarm"
						component={() => <BeefarmPage />}
					/>
					<Route
						path="/nectar"
						component={() => <NectarPage />}
					/>
					<Route
						path="/askhelp"
						component={() => <AskHelpPage />}
					/>
					{/* <Route
						path="/video"
						component={() => <VideoListPage />}
					/> */}
					<Route
						path="/video"
						component={() => <VideoListNewPage />}
					/>
					<Route
						path="/"
						component={() => <BeefarmPage />}
					/>
				</Switch>
			</div>
		</FullScreenContainer>
	)
}

export default IndexScreen
