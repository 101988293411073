import store from 'cmn-utils/lib/store'

export default {
	request: {
		prefix: '/api',

		// 每次请求头部都会带着这些参数
		withHeaders: () => ({
			[`authority-token`]: store.getStore('token'),
		}),

		/**
		 * 因为modelEnhance需要知道服务器反回的数据，
		 * 什么样的是成功，什么样的是失败，如
		 * {status: true, data: ...} // 代表成功
		 * {status: false, message: ...} // 代表失败
		 * 实际中应该通过服务端反回的response中的
		 * 成功失败标识来进行区分
		 */
		afterResponse: response => {
			return response
		},
	},
}
