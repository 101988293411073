import React from 'react'
import Modal from '.'
import icon_close from 'assets/beefarm/icon_tanexit.png'

import './content_modal.less'

const ContentModal = props => {
	return (
		<Modal
			className={`content-modal ${props.className}`}
			visible={props.visible}
			style={{ width: props.width ?? '60%', height: props.height ?? '60%' }}
			onCancel={props.onCancel}
			maskClosable={props.maskClosable}
		>
			<div className="title">
				<span>{props.title}</span>
				<img src={icon_close} alt="" onClick={props.onCancel} />
			</div>
			{props.children}
		</Modal>
	)
}

export default ContentModal
