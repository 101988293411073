import React from 'react'
import './index.less'

const Modal = props => {
	return props.visible ? (
		<div
			className={`modal-mask ${props.className ?? ''}`}
			onClick={e => {
				if (e.target.className.indexOf('modal-mask') > -1 && props.maskClosable !== false) props.onCancel()
			}}
		>
			<div className="modal-wrap" style={props.style}>
				<div className={`body ${props.loadingCenter ? 'align-center' : ''}`}>{props.children}</div>
			</div>
		</div>
	) :
		<></>
}

export default Modal
