import React, { useEffect, useState } from 'react'
import $$ from 'cmn-utils'
import { ContentLayout, IScrollBoard, PuffLoading } from 'components'
import TableModal from './table_modal'

const BeefarmList = props => {
	const [loading, setLoading] = useState(true)
	const [dataSource, setDataSource] = useState(null)
	const [originData, setOriginData] = useState(null)

	useEffect(() => {
		$$.post('/data/nectar/nectarGround', { pageNumber: 1, pageSize: 20 }).then(res => {
			if (res.code !== 1) {
				setLoading(false)
				return
			}
			if (!res.data.records.length) {
				setLoading(false)
				setDataSource([])
				props.onRandomFarmId(undefined)
				return
			}
			setOriginData(res.data.records)
			// const randomBeefarmList = res.data.records.sort((a, b) => b.hivesNumber - a.hivesNumber)

			// props.onRandomFarmId(randomBeefarmList.length > 0 ? randomBeefarmList[0].id : null)
			setDataSource(res.data.records.map(d => [d.areaName, d.nectarSourceArea + '亩']))
			setLoading(false)
		})
	}, [])

	return (
		<>
			<ContentLayout title="蜜源地" icon="ic_laiyuan" onActionClick={() => {
				$$.trigger('showNectarList')
			}}>
				{loading || dataSource === null ? (
					<div className="content-layout-loading">
						<PuffLoading></PuffLoading>
					</div>
				) : !dataSource.length ? (
					<div className="no-data">暂无蜜源地</div>
				) : (
							<IScrollBoard
								showDian={true}
								rowNum={4}
								columnWidth={[40, 0.14 * document.body.clientWidth]}
								align={['left', 'left', 'right']}
								data={dataSource}
								onClick={e => {
									const farm = originData.filter(d => d.name === e.row[1])

									props.onRandomFarmId(farm.length ? farm[0].id : null)
								}}
							/>
						)}
			</ContentLayout>
			<TableModal />
		</>
	)
}

export default BeefarmList
