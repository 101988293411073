import React from 'react'
import Modal from '.'
import $$ from 'cmn-utils'
import PuffLoader from 'react-spinners/PuffLoader'
import icon_location from 'assets/beefarm/icon_diqu2.png'

import './card_modal.less'

const BeefarmModal = props => {
	const [loading, setLoading] = React.useState(true)
	const [dataSource, setDataSource] = React.useState(null)

	React.useEffect(() => {
		if (!props.areaCode) return
		$$.post(`/data/panel/area/${props.areaCode}`).then(res => {
			if (res.code !== 1) return
			setDataSource(res.data)
			setLoading(false)
		})
	}, [props.areaCode])

	return (
		<Modal
			className="beefarm-modal "
			visible={props.visible}
			style={{width: 365, left: '80%'}}
			onCancel={props.onCancel}
			loadingCenter={loading}
		>
			{loading ? (
				<PuffLoader loading={loading} size={60} color="#17b7e3" />
			) : (
				<>
					<div className="title">
						<span>{props.areaName}</span>
						<img src={icon_location} alt="" />
					</div>
					<div className="content">
						<span className="content-title">蜂场统计</span>
						<span className="data-row">
							<span className="label">总蜂场数：</span>
							<span className="value">{dataSource?.farmInfo?.farmCount}</span>
						</span>
						<span className="data-row">
							<span className="label">总蜂箱/蜂群数：</span>
							<span className="value">{dataSource?.farmInfo?.beeCount}</span>
						</span>
						<span className="data-row">
							<span className="label">总预产量：</span>
							<span className="value">{dataSource?.farmInfo?.yield}</span>
						</span>
						<span className="data-row">
							<span className="label">总养蜂户：</span>
							<span className="value">{dataSource?.farmInfo?.beekeeper}</span>
						</span>
						<span className="content-title">蜂种种类</span>
						{dataSource?.beeSpecs?.map(b => {
							return (
								<span className="data-row" key={b.id}>
									<span className="label">{b.name}：</span>
									<span className="value">{b.beeFarmCount}</span>
									<span className="label">场 / </span>
									<span className="value">{b.beehiveCount}</span>
									<span className="label">箱</span>
								</span>
							)
						})}
						<span className="content-title">蜜源种类</span>
						{dataSource?.nectars?.map(b => {
							return (
								<span className="data-row" key={b.id}>
									<span className="label">{b.name}：</span>
									<span className="value">{b.beeFarmCount}</span>
									<span className="label">场 / </span>
									<span className="value">{b.beehiveCount}</span>
									<span className="label">箱</span>
								</span>
							)
						})}
					</div>
					<div className="footer">*进入区域图可查看此地区更多数据信息！</div>
				</>
			)}
		</Modal>
	)
}

export default BeefarmModal
